import { useEffect, useRef, useState } from 'react'

// eslint-disable-next-line
const useMountEffect = (callback, idScope) => useEffect(callback, [idScope])

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ]
}

const useCountdown = (initialCountdownTime, callback) => {
  const [countdown, setCountdown] = useState(initialCountdownTime)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1)
      }
    }, 1000)

    return () => clearTimeout(timer)
  })

  useEffect(() => {
    if (countdown <= 0) {
      callback()
    }
  })

  return () => countdown
}

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export { useCountdown, useFocus, useMountEffect, useOnClickOutside }
