import meetingService from '../services/meetings'
import { HOURS_LABEL, MINUTES_LABEL } from '../components/meeting/timeRange/constants'

const load = (id, query, cancelToken) => {
  return show(id, query, cancelToken)
    .then(response => response.attributes)
    .then(attributes => {
      const { minTime, maxTime, name } = attributes

      const newTimes = {
        min: minTime > 45 ? minTime / 60 : minTime,
        max: maxTime > 45 ? maxTime / 60 : maxTime,
      }

      const newTypes = {
        min: minTime <= 45 ? MINUTES_LABEL : HOURS_LABEL,
        max: maxTime <= 45 ? MINUTES_LABEL : HOURS_LABEL,
      }

      return {
        times: newTimes,
        types: newTypes,
        name,
      }
    })
  }

const create = (meeting, query, cancelToken) => {
  meeting = {
    ...meeting,
    userId: localStorage.getItem('userId'),
  }

  const params = {
    meeting,
    ...Object.fromEntries(query)
  }

  return meetingService.create(params, cancelToken)
}

const show = (id, query, cancelToken) => {
  const params = `${id}?${query}`

  return meetingService.show(params, cancelToken)
}

const update = (id, meeting, cancelToken) => {
  const params = {
    meeting,
  }

  return meetingService
    .update(id, params, cancelToken)
}

const requests = { create, load, show, update }

export default requests
