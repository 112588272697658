import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Labels from './Labels'
import { Form } from '../meeting'
import Availability from './Availability'
import TimeState from '../../models/TimeState'
import availabilityRequest from '../../requests/availabilities'
import CurrentUserContext from '../../contexts/CurrentUserContext'
import LoadingContext from '../../contexts/LoadingContext'
import ErrorContext from '../../contexts/ErrorContext'
import Errors from '../../helpers/errors'
import Header from '../structure/Header'

const New = () => {
  const { meetingId } = useParams()

  const { currentUser } = useContext(CurrentUserContext)
  const newAvailability = new TimeState({ zone: currentUser.timeZone })

  const { setLoading } = useContext(LoadingContext)
  const { setError } = useContext(ErrorContext)
  const navigate = useNavigate()

  const handleCreate = newTimeState => {
    Errors.reset(setError)
    setLoading(true)

    newTimeState.toTimeZone(currentUser.timeZone)

    return availabilityRequest
      .create(meetingId, newTimeState)
      .then(() => {
        setLoading(false)

        navigate(`/meetings/${meetingId}`)
      })
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => handleCreate(newTimeState), setError)
      })
  }

  const handleCancel = () => {
    navigate(`/meetings/${meetingId}`)
  }

  return (
    <Form meetingId={meetingId}>
      <Header backPath={`/meetings/${meetingId}`}>New Availability</Header>

      <Labels />

      <Availability
        id="new"
        availability={newAvailability}
        handleCreate={handleCreate}
        handleCancel={handleCancel}
      />
    </Form>
  )
}

export default New
