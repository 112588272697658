import React from 'react'
import IconListItem from './IconListItem'

const NewMeeting = () => {
  return (
    <div className="help-container">
      <h3>Creating a Meeting</h3>

      <p>
        Enter a name for your meeting, and then select the min and max time boundaries.
        This is how short or long your meeting will be.
        After users enter their availabilities, matching meeting times are generated
        using the min and max time boundaries.
      </p>

      <h4>For Example...</h4>
      <p>
        Let's say you want to schedule a boardgame night with your friends.
        You know you want to hang out for at least an hour, but can't do more than four -
        it's just too much boardgaming! You would here select a min time of 1 Hour,
        and a max time of 4 Hours.
        Later, after everyone has entered their availabilities, the meeting times that are generated
        will be no shorter than one hour, and no longer than four.
      </p>

      <hr />

      <ul>
        <IconListItem>
          <span className="small-button">Create</span> to save the meeting
        </IconListItem>
      </ul>
    </div>
  )
}

export default NewMeeting
