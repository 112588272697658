const validateTimeChange = (hour, minute) => {
  const hourAllowed = hour >= 0 && hour < 24
  const minuteAllowed = [0, 15, 30, 45, 59].includes(minute)

  if (hourAllowed && minuteAllowed) {
    return
  }

  throw new Error(`Time change not allowed for [${hour}, ${minute}]`)
}

export default validateTimeChange
