import React from 'react'
import { PencilIcon } from '@heroicons/react/24/solid'

import ActionLink from './ActionLink'

const Edit = props => {
  return (
    <ActionLink title="Edit" {...props}>
      <PencilIcon className="h-4" />
    </ActionLink>
  )
}

export default Edit
