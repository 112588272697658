import React from 'react'
import { useNavigate } from 'react-router-dom'

import UserAvailabilityRow from './UserAvailabilityRow'

const UserAvailabilities = ({ meetingId, availabilities }) => {
  const navigate = useNavigate()

  const handleClick = userToken => event => {
    event.preventDefault()

    navigate(`/meetings/${meetingId}/availabilities/${userToken}`)
  }

  return (
    <ul className="col-span-12 availabilities-container">
      {Object.entries(availabilities).map(([userToken, userAvailabilities]) => {
        const { creatorName: userName } = userAvailabilities[0]

        return (
          <UserAvailabilityRow
            key={userToken}
            userName={userName}
            userToken={userToken}
            userAvailabilities={userAvailabilities}
            handleClick={handleClick}
          />
        )
      })}
    </ul>
  )
}

export default UserAvailabilities
