import React from 'react'

const Footer = () => {
  return (
    <p className="text-xs float-right p-2">
      favicons
      {' '}
      made by
      {' '}
      <a href="https://www.flaticon.com/authors/freepik" title="Freepik" className="link">
        Freepik
      </a>
      {' '}
      from
      {' '}
      <a href="https://www.flaticon.com/" title="Flaticon" className="link">
        flaticon.com
      </a>
    </p>
  )
}

export default Footer
