import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Routes, Route } from 'react-router-dom'
import { Settings as LuxonSettings } from 'luxon'
import { isNull } from 'lodash'

import Loading from './components/structure/Loading'
import AppError from './models/AppError'
import ResponseError from './models/ResponseError'

import { ERROR_TYPES } from './helpers/constants'
import { useMountEffect } from './helpers/effects'
import { clearUserStorage, createUserStorage } from './helpers/user'
import Errors from './helpers/errors'
import Site from './components/structure/Site'

import usersRequest from './requests/users'

import { CurrentUserProvider } from './contexts/CurrentUserContext'
import { CancelTokenProvider } from './contexts/CancelTokenContext'
import { ErrorProvider } from './contexts/ErrorContext'
import { LoadingProvider } from './contexts/LoadingContext'
import { RemountProvider } from './contexts/RemountContext'

import './App.css'
import './tailwind.out.css'

function App() {
  const newError = new AppError()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(newError.state)
  const [preparing, setPreparing] = useState(true)
  const [remountCount, setRemountCount] = useState(0)
  const [storage] = useState({
    id: localStorage.getItem('userId'),
    name: localStorage.getItem('name') || '',
    timeZone: localStorage.getItem('timeZone'),
    token: localStorage.getItem('userToken'),
    saved: false,
    meetingTokens: [],
  })

  const [currentUser, setCurrentUser] = useState({
    id: null,
    name: '',
    timeZone: 'UTC',
    token: null,
    saved: false,
    meetingTokens: [],
    theme: "light",
  })

  const cancelTokenSource = axios.CancelToken.source()
  const { token: cancelToken } = cancelTokenSource

  LuxonSettings.defaultZone = 'utc'

  const updateCurrentUser = user => {
    const { token, name, saved, timeZone, meetingTokens } = user

    if (isNull(localStorage.getItem('timeZone'))) {
      localStorage.setItem('timeZone', timeZone)
    }

    setCurrentUser({ ...currentUser, token, name, timeZone, saved, meetingTokens })

    return Promise.resolve(user)
  }

  const clearCurrentUser = () => {
    setCurrentUser({ id: null, token: null, timeZone: null, name: '', saved: false })

    return Promise.resolve()
  }

  const extractUserAttributes = user => {
    return Promise.resolve(user.attributes)
  }

  const createUser = (count = 0) => {
    return usersRequest
      .create(cancelToken)
      .then(extractUserAttributes)
      .then(createUserStorage)
      .then(updateCurrentUser)
      .catch(error => {
        count += 1

        if (count < 3) {
          setTimeout(() => {}, 5000)

          createUser(count)
        } else {
          const newError = new ResponseError({
            active: true,
            message: "Something went wrong",
            type: ERROR_TYPES.app,
          })

          setLoading(false)
          setError(newError.state)
        }

        Errors.notify(error)
      })
  }

  const fetchUser = (count = 0) => {
    return usersRequest
      .show(storage.token, cancelToken)
      .then(extractUserAttributes)
      .then(updateCurrentUser)
      .catch(error => {
        if (error.response && error.response.status === 404) {
          return clearUserStorage()
            .then(clearCurrentUser)
            .then(createUser)
        }

        count += 1

        if (count < 3) {
          setTimeout(() => fetchUser(count), 5000)
        } else {
          const newError = new ResponseError({
            active: true,
            message: "Something went wrong",
            type: ERROR_TYPES.app,
          })

          setLoading(false)
          setError(newError.state)
        }
      })
  }

  useMountEffect(() => {
    setLoading(true)

    if (isNull(storage.id) || isNull(storage.token)) {
      clearUserStorage()
        .then(createUser)
        .then(() => setLoading(false))
    } else {
      fetchUser()
        .then(() => setLoading(false))
    }

    return () => cancelTokenSource.cancel('Unmounting')
  })

  useEffect(() => {
    if (!isNull(currentUser.token) && currentUser.token !== '') {
      setPreparing(false)
    }
  }, [currentUser])

  return (
    <ErrorProvider value={{ error, setError }}>
      <LoadingProvider value={{ loading, setLoading }}>
        <CancelTokenProvider value={{ cancelTokenSource, cancelToken, }}>
          <RemountProvider value={{ remountCount, setRemountCount }}>
            <CurrentUserProvider value={{ currentUser, setCurrentUser }}>
              {preparing && (
                <Routes>
                  <Route path="*" element={<Loading />} />
                  <Route path="meetings/" element={<Loading />} />
                  <Route path="meetings/:id/edit" element={<Loading />} />
                  <Route path="meetings/:meetingId/settings" element={<Loading />} />
                  <Route path="meetings/:meetingId" element={<Loading />} />
                  <Route path="meetings/:meetingId/availabilities/:userToken/new" element={<Loading />} />
                  <Route path="meetings/:meetingId/availabilities/new" element={<Loading />} />
                  <Route path="meetings/:meetingId/availabilities/:id/edit" element={<Loading />} />
                  <Route path="meetings/:meetingId/availabilities/:userToken" element={<Loading />} />
                  <Route path="profile" element={<Loading />} />
                  <Route path="sign-out" element={<Loading />} />
                </Routes>
              )}

              {!preparing && (
                <Site />
              )}
            </CurrentUserProvider>
          </RemountProvider>
        </CancelTokenProvider>
      </LoadingProvider>
    </ErrorProvider>
  )
}

export default App
