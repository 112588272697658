import { MINUTES_ARRAY } from "../constants";

const convertTimeToPosition = (hour, minute) => {
  const positionFromMinute = MINUTES_ARRAY.findIndex(item => item === minute) + 1
  const preceeding = hour * 4

  return preceeding + positionFromMinute
}

const convertTimesToPositions = (startTime, endTime) => {
  return {
    startTime: convertTimeToPosition(startTime.hour, startTime.minute),
    endTime: convertTimeToPosition(endTime.hour, endTime.minute),
  }
}

export { convertTimeToPosition, convertTimesToPositions }
