import React from 'react'
import PropTypes from 'prop-types'

import FormFields from '../../formFields'
import Buttons from '../../formFields/buttons'

const New = props => {
  const {
    persisted,
    handleCancelClick,
    handleSaveClick,
  } = props

  return (
    <>
      <div className={`col-span-6 md:hidden`}>
        <Buttons.Submit className="w-full press-me" handleOnClick={handleCancelClick}>
          Cancel
        </Buttons.Submit>
      </div>

      <div className={`col-span-6 md:hidden`}>
        <Buttons.Submit className="w-full press-me" handleOnClick={handleSaveClick}>
          {persisted ? 'Update' : 'Create'}
        </Buttons.Submit>
      </div>

      <div className="hidden md:col-span-1 md:flex-center">
        <FormFields.Buttons.Cancel handleOnClick={handleCancelClick} />
      </div>

      <div className="hidden md:col-span-1 md:flex-center">
        <FormFields.Buttons.Accept handleOnClick={handleSaveClick} />
      </div>
    </>
  )
}

New.propTypes = {
  persisted: PropTypes.bool.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
}

export default New
