import React from 'react'
import { DateTime } from 'luxon'

import Grid, { Row } from './grid'
import Title from './Title'

const Crash = () => {
  return (
    <div className="crash">
      <Grid className="bg-white z-10 h-full flex justify-center items-center p-8">
        <Title className="flex-center">
          Something went wrong
        </Title>

        <Row className="my-2">
          <hr />
        </Row>

        <Row>
          <p>
            It seems things aren't going as... <em>planned</em>.
            {' '}
            Refreshing the page might work, or feel free to reach out to us at
            {' '}
            <a href="https://twitter.com/ZeitplanApp" className="link" target="_blank" rel="noopener noreferrer">@ZeitplanApp</a>.
          </p>
        </Row>

        <Row className="my-2">
          <hr />
        </Row>

        <Row className="text-xs">
          This error has been logged @ {DateTime.local().toString()}
        </Row>
      </Grid>
    </div>
  )
}

export default Crash
