import React from 'react'

import CopyField from '../formFields/CopyField'

const Permalink = ({ id }) => {
  let hostname = window.location.hostname

  if (hostname === 'localhost') {
    hostname = `${hostname}:8080`
  }

  const value = `https://${hostname}/meetings/${id}`

  return (
    <>
      <CopyField value={value} />

      <hr className="col-span-12" />
    </>
  )
}

export default Permalink;
