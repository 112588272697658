const SELECT_TYPES = { startDay: 'startDay', endDay: 'endDay', startTime: 'startTime', endTime: 'endTime' }

const DAY_FIELDS = [SELECT_TYPES.startDay, SELECT_TYPES.endDay]
const DAY_NAMES = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
const ERROR_TYPES = { app: 'app', ajax: 'ajax' }
const HOURS_ARRAY = [...Array(24).keys()].map(n => n);
const MINUTES_ARRAY = [0, 15, 30, 45];
const PERIODS = { am: 'am', pm: 'pm' };
const TIME_TYPES = { military: 'military', standard: 'standard' };

const TIME_MAP = HOURS_ARRAY.flatMap(hour =>
  MINUTES_ARRAY.map(minute => {
    return [hour, minute];
  }));

export {
  DAY_FIELDS,
  DAY_NAMES,
  ERROR_TYPES,
  HOURS_ARRAY,
  MINUTES_ARRAY,
  PERIODS,
  SELECT_TYPES,
  TIME_TYPES,
  TIME_MAP,
};
