import NewMeeting from './NewMeeting'
import EditMeeting from './EditMeeting'
import Profile from './Profile'
import AvailabilitiesList from './AvailabilitiesList'
import Meetings from './Meetings'
import NewAvailability from './NewAvailability'
import EditAvailability from './EditAvailability'
import SignIn from './SignIn'

const Doc = () => {}

Doc.NewMeeting = NewMeeting
Doc.EditMeeting = EditMeeting
Doc.Profile = Profile
Doc.AvailabilitiesList = AvailabilitiesList
Doc.Meetings = Meetings
Doc.NewAvailability = NewAvailability
Doc.EditAvailability = EditAvailability
Doc.SignIn = SignIn

export default Doc
