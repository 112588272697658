import React from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid'
import { isNull } from 'lodash'

import Title from './Title'

const Header = (props) => {
  const navigate = useNavigate()

  const {
    backPath = null,
    onBackClick,
    showBackButton = true,
    children
  } = props

  const handleBackClick = () => {
    if (onBackClick) {
      return onBackClick()
    }

    navigate(-1)
  }

  return (
    <Title>
      {!isNull(backPath) && backPath !== '' && (
        <Link to={backPath} className="absolute m-3">
          <ArrowLeftCircleIcon className="h-6" />
        </Link>
      )}

      {(isNull(backPath) || backPath === '') && showBackButton && (
        <button onClick={handleBackClick} className="absolute m-3">
          <ArrowLeftCircleIcon className="h-6" />
        </button>
      )}

      <div className="flex-center m-3">
        {children}
      </div>
    </Title>
  )
}

Header.propTypes = {
  backPath: PropTypes.string,
  onBackClick: PropTypes.func,
  showBackButton: PropTypes.bool,
}

export default Header
