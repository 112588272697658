import React from 'react'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import IconListItem from './IconListItem'

const AvailabilitiesList = () => {
  return (
    <div className="help-container">
      <h3>Meeting Details</h3>

      <p>
        This is your meeting's details, which displays the meeting name, the min and max times of the meeting,
        and the <span className="proper-noun">Meeting Permalink</span>.
        Under this are the <span className="proper-noun">Calculated Meeting Times</span>,
        and then your own availability.
      </p>

      <h4>Meeting Permalink</h4>

      <p>
        Share your <span className="proper-noun">Meeting Permalink</span> with the other meeting attendees.
        Use the copy button <ClipboardDocumentIcon className="h-6" /> to easily copy the link.
      </p>

      <h4>Calculated Meeting Times</h4>

      <p>
        When enough attendees have entered their availabilities, Zeitplan will display a
        list of the best meeting times for the most people. For example:
        <br />
        <br />
        Let's say you want to meet with your friend Dean for lunch each week. You know you can't meet for more than an hour,
        which means the min time of the meeting is 15 minutes, and the max time is 1 hour.
      </p>

      <ul className="list-disc">
        <li>You are available Monday @ 11AM-1PM, and Wednesday @ 10AM-2PM.</li>
        <li>Dean is available Tuesday @ 10AM-12PM, and Wednesday @ 12PM-1PM.</li>
      </ul>

      <br />

      <p>
        The best meeting time that is available is Wednesday from 12pm-1pm. (Technically, there are a lot of smaller
        meeting times, but <span className="proper-noun">Zeitplan</span> finds the <i>best</i> time:
        the first point where you both can meet, up to the maximum time.)
      </p>

      <p>
        Note that if no availabilities overlap and meet the requirements of min and max meeting time,
        there will not be any calculated meeting time.
      </p>

      <h4>My Avalability</h4>

      Each of your available times will be listed here. You can edit or delete them.

      <hr />

      <ul>
        <IconListItem icon="pencil">
          next to the meeting name to edit the meeting name and times
        </IconListItem>

        <IconListItem icon="clipboardCopyOutline">
          to copy the Meeting Permalink
        </IconListItem>

        <IconListItem icon="pencil">
          next to the saved availability to edit the availability
        </IconListItem>

        <IconListItem icon="trashOutline">
          next to the saved availability to delete the availability
        </IconListItem>

        <IconListItem icon="rewindOutline">
          after deleting an availability to undo deletion
        </IconListItem>

        <IconListItem>
          New Availability to add your availability
        </IconListItem>
      </ul>
    </div>
  )
}

export default AvailabilitiesList
