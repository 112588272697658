import React from 'react'
import { BackwardIcon } from '@heroicons/react/24/outline'

import ActionButton from './ActionButton'

const Undo = ({ countdown, ...rest }) => {
  return (
    <ActionButton title="Undo" {...rest}>
      <BackwardIcon className="h-6" />
      <span>
        {countdown()}
      </span>
    </ActionButton>
  )
}

export default Undo
