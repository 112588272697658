import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { isUndefined } from 'lodash'

import LoadingContext from '../../../contexts/LoadingContext'

const ActionLink = ({ to, className, disabled, children, ...rest }) => {
  if (isUndefined(to)) {
    throw new Error('`to` must be defined to use ActionLink')
  }

  const { loading } = useContext(LoadingContext)

  const classes = classNames(
    "action-button",
    className,
    { "disabled": (loading || disabled) }
  )

  const handleClick = event => {
    if (loading || disabled) {
      event.preventDefault()
    }
  }

  return (
    <Link to={to} className={classes} onClick={handleClick} {...rest}>
      {children}
    </Link>
  )
}

export default ActionLink
