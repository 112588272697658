import React from 'react'

const Label = ({ children, ...rest }) => {
  return (
    <label className="block text-gray-700 text-sm" {...rest}>
      {children}
    </label>
  )
}

export default Label
