import React from 'react'
import PropTypes from 'prop-types'

import Availabilities from './index'
import { useCountdown } from '../../helpers/effects'
import FormFields from '../formFields'
import TimeState from '../../models/TimeState'

const DeletedAvailability = ({ id, availability, meetingId, handleOnClick, handleUndoExpire }) => {
  const getCountdown = useCountdown(10, () => handleUndoExpire(id))

  const handleClick = () => {
    const timeState = new TimeState({ ...availability, id, deleted: false })

    handleOnClick(id, timeState)
  }

  return (
    <>
      <Availabilities.Mobile.Show
        availability={availability}
        meetingId={meetingId}
        deleted={true}
        handleUndoClick={handleClick}
        undoCountdown={getCountdown}
      />

      <div className="hidden col-span-10 availability-col md:flex-center">
        <p>Availability Deleted</p>
      </div>

      <div className="hidden col-span-2 availability-col md:flex-center">
          <FormFields.Buttons.Undo
            className="hidden w-full small-link press-me md:flex-center"
            countdown={getCountdown}
            handleOnClick={handleClick}
          />
      </div>
    </>
  )
}

DeletedAvailability.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  meetingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleUndoExpire: PropTypes.func.isRequired,
}

export default DeletedAvailability
