import React from 'react'

const Empty = () => {
  return (
    <div className="col-span-12 flex-center">
      <p>
        No Availabilities. Click&nbsp;
        <span className="proper-noun">New Availability</span>&nbsp;
        to get started!
      </p>
    </div>
  )
}

export default Empty
