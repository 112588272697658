import { TIME_TYPES } from "../constants";
import convertToStandardTime from "./convertToStandardTime";

const convertMilitaryTimeToString = (hour, minute) => {
  return `${hour}:${minute.toString().padStart(2, '0')}`;
};

const convertStandardTimeToString = (hour, minute) => {
  const [standardHour, standardPeriod] = convertToStandardTime(hour);

  return `${standardHour}:${minute.toString().padStart(2, '0')}${standardPeriod}`;
};

const convertTimeToString = (hour, minute, type) => {
  if (type === TIME_TYPES.standard) {
    return convertStandardTimeToString(hour, minute);
  }

  return convertMilitaryTimeToString(hour, minute);
};

export default convertTimeToString;
