import React from 'react'
import jstz from 'jstimezonedetect'

import FormFields from '../../formFields'
import { SubGrid } from '../grid'

const TimeZoneSelect = ({ value, handleTimeZoneChange }) => {
  const renderTimeZoneSelect = () => {
    return Object.values(jstz.olson.timezones).sort().map((timeZone, index) => {
      return (
        <option key={index} value={timeZone}>
          {timeZone.replace(/[_-]/g, " ")}
        </option>
      )
    })
  }

  const handleChange = event => {
    event.preventDefault()

    handleTimeZoneChange(event.target.value)
  }

  return (
    <div className="col-span-12">
      <SubGrid>
        <div className="col-span-12 md:col-span-6 w-full flex-center">
          <FormFields.Label htmlFor="user_time_zone">Time Zone</FormFields.Label>
        </div>

        <div className="col-span-12 md:col-span-6 w-full flex-center">
          <FormFields.Select
            id="user_time_zone"
            value={value}
            onChange={handleChange}
          >
            {renderTimeZoneSelect()}
          </FormFields.Select>
        </div>
      </SubGrid>
    </div>
  )
}

export default TimeZoneSelect
