import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Buttons from '../../formFields/buttons'

const Show = props => {
  const {
    disabled,
    handleDeleteClick,
    handleEditClick,
  } = props

  const classes = classNames("hidden md:flex-center md:col-span-1")

  return (
    <>
      <div className={classes}>
        <Buttons.Edit disabled={disabled} handleOnClick={handleEditClick} />
      </div>

      <div className={classes}>
        <Buttons.Delete disabled={disabled} handleOnClick={handleDeleteClick} />
      </div>
    </>
  )
}

Show.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
}

export default Show
