import React, { useContext, useState } from 'react'

import BackButton from '../BackButton'
import Grid from '../grid'
import Title from '../Title'
import Signin from './Signin'

import User from '../../../models/User'

import { useMountEffect } from '../../../helpers/effects'
import usersRequests from '../../../requests/users'

import CurrentUserContext from '../../../contexts/CurrentUserContext'
import CancelTokenContext from '../../../contexts/CancelTokenContext'
import ErrorContext from '../../../contexts/ErrorContext'
import LoadingContext from '../../../contexts/LoadingContext'
import Errors from '../../../helpers/errors'
import TimeZoneSelect from './TimeZoneSelect'
import Name from './Name'
import { useParams } from 'react-router'

const Settings = () => {
  const { meetingId } = useParams()
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const { cancelTokenSource, cancelToken } = useContext(CancelTokenContext)
  const { setError } = useContext(ErrorContext)
  const { setLoading } = useContext(LoadingContext)

  const [currentTimeZone, setCurrentTimeZone] = useState('UTC')

  const loadUser = () => {
    setLoading(true)

    return usersRequests
      .show(currentUser.token, cancelToken)
      .then(user => new User(user.attributes))
      .then(user => {
        setCurrentTimeZone(user.timeZone)

        return user
      })
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadUser(), setError)
      })
  }

  const updateCurrentUser = params => {
    return usersRequests
      .update(params, cancelToken)
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => updateCurrentUser(params), setError)
      })
  }

  useMountEffect(() => {
    loadUser()

    return () => cancelTokenSource.cancel('Unmounting')
  }, meetingId)

  const handleTimeZoneChange = (event) => {
    setLoading(true)

    const newTimeZone = event.target.value
    const params = {
      user: { timeZone: newTimeZone }
    }

    setCurrentTimeZone(newTimeZone)
    setCurrentUser({ ...currentUser, timeZone: newTimeZone })
    updateCurrentUser(params)
  }

  const handleUserNameChange = name => {
    setLoading(true)

    const params = {
      user: { name }
    }

    setCurrentUser({ ...currentUser, name })

    return updateCurrentUser(params)
  }

  const handleClickUserToken = user => {
    const { id, token, name } = user

    localStorage.setItem('userId', id)
    localStorage.setItem('userToken', token)
    localStorage.setItem('name', name)

    setCurrentUser({ ...currentUser, name, token })
  }

  return (
    <Grid className="card">
      <Title>
        <BackButton to={`../`} className="h-6" />

        <div className="flex-center m-3">
          Settings
        </div>
      </Title>

      <Name
        initialValue={currentUser.name}
        handleUserNameChange={handleUserNameChange}
      />

      <TimeZoneSelect
        currentTimeZone={currentTimeZone}
        handleTimeZoneChange={handleTimeZoneChange}
      />

      <hr className="col-span-12" />

      <Signin
        meetingId={meetingId}
        currentUserToken={currentUser.token}
        handleClickUserToken={handleClickUserToken}
      />
    </Grid>
  )
}

export default Settings
