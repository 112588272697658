import { DateTime } from "luxon"
import { isNil } from 'lodash'

import { generateTimeState as generateState } from "../helpers"
import degenerateTimeState from "../helpers/time/degenerateTimeState"

class TimeState {
  constructor(params = {}) {
    const {
      id,
      meetingId,
      allDay,
      creatorName,
      creatorToken,
      deleted,
      persisted,
      saved,
      startDateTime,
      endDateTime,
      users,
      zone = 'utc',
    } = params

    const currentTime = DateTime.local({ zone })
    let nextHour = currentTime.hour
    let nextMinute = currentTime.minute + 15

    if (nextMinute >= 60) {
      nextMinute -= 60
      nextHour += 1
    }

    if (nextHour >= 24) {
      nextMinute = 59
      nextHour = 23
    }

    const nextTime = DateTime.fromObject({ hour: nextHour, minute: nextMinute }, { zone })

    this.id = isNil(id) ? 'new' : id
    this.meetingId = meetingId
    this.creatorName = isNil(creatorName) ? '' : creatorName
    this.creatorToken = creatorToken
    this.allDay = allDay
    this.persisted = isNil(persisted) ? false : persisted
    this.saved = isNil(saved) ? false : saved
    this.deleted = deleted
    this.startDateTime = isNil(startDateTime) ? currentTime : startDateTime
    this.endDateTime = isNil(endDateTime) ? nextTime : endDateTime
    this.users = users
  }

  get state() {
    return generateState({
      id: this.id,
      meetingId: this.meetingId,
      creatorName: isNil(this.creatorName) ? false : this.creatorName,
      creatorToken: isNil(this.creatorToken) ? false : this.creatorToken,
      allDay: isNil(this.allDay) ? false : this.allDay,
      persisted: this.persisted,
      saved: this.saved,
      deleted: isNil(this.deleted) ? false : this.deleted,
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      users: isNil(this.users) ? false : this.users,
    })
  }

  get toWire() {
    let wire = {
      startTime: this.startDateTime,
      endTime: this.endDateTime,
    }

    if (!isNil(this.allDay)) {
      wire.allDay = this.allDay
    }

    if (!isNil(this.deleted)) {
      wire.deleted = this.deleted
    }

    return wire
  }

  static degenerateState(id, state) {
    const { start, end, ...rest } = state
    const newDateTimes = degenerateTimeState(start, end)

    return new TimeState({
      ...newDateTimes,
      ...rest,
      id,
    })
  }

  toUTC() {
    this.startDateTime = this.startDateTime.toUTC()
    this.endDateTime = this.endDateTime.toUTC()
  }

  toTimeZone(zone) {
    this.startDateTime = this.startDateTime.setZone(zone, { keepLocalTime: true })
    this.endDateTime = this.endDateTime.setZone(zone, { keepLocalTime: true })
  }
}

export default TimeState
