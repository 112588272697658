import React from 'react'
import classNames from 'classnames'

import Grid from './grid'

const Faq = () => {
  const faqClassNames = classNames({
    "w-full": true,
    "card": true,
    "list-disc": true,
  })


  return (
    <Grid>
      <div className="col-span-12">
        <h3 className="faq-header">
          F.A.Q.
        </h3>

        <ul className={faqClassNames}>
          {/* <Guide /> */}

          <li>
            <strong>How do I make an account?</strong>
            <p>
              There is no "sign up" page, and there are no accounts. Zeitplan is meant to be the quickest way to schedule a recurring meeting,
              and a major design goal is to have as few things to remember as possible.
            </p>
          </li>

          <li>
            <strong>Why do I have a profile if I have no account?</strong>
            <p>
              To be able to switch between devices (computer, tablet, phone), you are assigned a random token when you use Zeitplan.
              Because there is no personally identifiable information in the app, all user tokens are shown on the profile page - just select
              the one that you have on your originating device and you will be "logged in".
            </p>
          </li>

          <li>
            <strong>How long is my Zeitplan stored?</strong>
            <p>
              Zeitplans have a seven (7) day lifespan. At the end of the seventh day, the Zeitplan is deleted.
              Once you have a Zeitplan created with all availabilities included, you can either print or screenshot the page
              for future reference.
            </p>
          </li>

          <li>
            <strong>What is min time & max time?</strong>
            <p>
              These are the boundaries, in minutes or hours, of the time required for the meeting. For instance, if the
              meeting might be as short as an hour, but no longer than four hours. These boundaries are then used to find the
              optimal time where other people can meet for <em>at least</em> an hour, but <em>no more than</em> four.
            </p>
          </li>

          <li>
            <strong>Why don't you have x feature?</strong>
            <p>
              I probably want to add it, but don't have the time right now. There are other cool apps that do similar things:
            </p>

            <ul>
              <li>
                <a href="http://when2meet.com" className="link">when2meet.com</a>
              </li>
              <li>
                <a href="http://doodle.com" className="link">doodle.com</a>
              </li>
            </ul>
          </li>

          <li>
            <strong>How do I get in touch?</strong>
            <p>
              Reach out on Twitter
              {' '}
              <a href="https://twitter.com/ZeitplanApp" target="_blank" className="link" rel="noopener noreferrer">
                @Zeitplan.App
              </a>
            </p>
          </li>
        </ul>
      </div>
    </Grid>
  )
}

export default Faq
