import React, { useState, useContext } from 'react'

import FormFields from '../formFields'
import usersRequest from '../../requests/users'
import { createUserStorage } from '../../helpers/user'
import User from '../../models/User'
import CancelTokenContext from '../../contexts/CancelTokenContext'
import Grid, { Row } from './grid'
import Buttons from '../formFields/buttons'
import { useNavigate } from 'react-router-dom'
import CurrentUserContext from '../../contexts/CurrentUserContext'
import Header from './Header'

const SignIn = () => {
  const [signInValue, setSignInValue] = useState('')
  const [signInErrors, setSignInErrors] = useState([])

  const { cancelToken } = useContext(CancelTokenContext)
  const { setCurrentUser } = useContext(CurrentUserContext)

  const navigate = useNavigate()

  const submitSignIn = () => {
    const value = signInValue.trim()

    if (value === '') {
      setSignInErrors(["can't be blank"])

      return
    }

    return usersRequest.show(value, cancelToken)
      .then(updateCurrentUser)
      .then(() => navigate('/meetings'))
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setSignInErrors(["user not found"])
        }
      })
  }

  const handleSignInFieldChange = event => {
    setSignInValue(event.target.value)
  }

  const handleSignInFieldKeyDown = event => {
    if (event.key === 'Enter') {
      return submitSignIn()
    }
  }

  const updateCurrentUser = user => {
    const newUser = new User(user.attributes)

    setCurrentUser(newUser)
    createUserStorage(newUser)
  }

  const handleSignInClick = event => {
    event.preventDefault()

    submitSignIn()
  }

  return (
    <Grid>
      <Header>Sign In</Header>

      <Row>
        <FormFields.Input
          placeholder="enter-user-token"
          value={signInValue}
          onChange={handleSignInFieldChange}
          onKeyDown={handleSignInFieldKeyDown}
        />
      </Row>
      <Row className={`h-8 flex-center w-full ${signInErrors.length > 0 ? 'error' : 'hidden'}`}>
        {signInErrors.join(', ')}
      </Row>
      <Row className="hidden md:flex md:col-span-6"></Row>
      <Row className="flex-center w-full md:col-span-6">
        <Buttons.Submit onClick={handleSignInClick} className="w-full press-me h-12">
          Sign In
        </Buttons.Submit>
      </Row>
    </Grid>
  )
}

export default SignIn
