import React from 'react'
import IconListItem from './IconListItem'

const EditMeeting = () => {
  return (
    <div className="help-container">
      <h3>Sign In</h3>

      <p>
        Sign in using your <span className="proper-noun">UserToken</span>.
      </p>

      <hr />

      <ul>
        <IconListItem>
          <span className="small-button">Sign In</span> to sign in
        </IconListItem>
      </ul>
    </div>
  )
}

export default EditMeeting
