const scrollPositionAmount = position => {
  const optionSize = 36
  const optionPadding = 4 // Number of options to "pad" with

  const hasPassedPadding = position >= optionPadding
  const positionLessPadding = position - optionPadding

  return hasPassedPadding ? positionLessPadding * optionSize : 0
}

const scrollPositionAmounts = positions => {
  const newScrollPositions = {}

  Object.keys(positions).forEach(position => {
    newScrollPositions[position] = scrollPositionAmount(position)
  })

  return newScrollPositions
}

export { scrollPositionAmount, scrollPositionAmounts }
