import { isNil } from 'lodash'

import AppError from './AppError'

class RespoonseError extends AppError {
  constructor(params = {}) {
    const { status, ...rest } = params

    super(rest)

    this.status = isNil(status) ? '' : status
  }

  get state() {
    return {
      ...super.state,
      status: this.status,
    }
  }
}

export default RespoonseError
