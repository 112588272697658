import { DateTime } from 'luxon'

import groupAvailabilityService from '../services/groupAvailabilities'
import TimeState from '../models/TimeState'

const index = (meetingId, cancelToken, zone = 'utc') => {
  return groupAvailabilityService
    .index(meetingId, cancelToken)
    .then(availabilities => {
      const newState = {}

      availabilities.forEach(item => {
        const { id, attributes: { startTime, endTime, ...rest }, relationships: { users }} = item
        const startDateTime = DateTime.fromISO(startTime, { zone })
        const endDateTime = DateTime.fromISO(endTime, { zone })

        if (users.length === 1) {
          return
        }

        return newState[id] = {
          ...new TimeState({
            id,
            saved: true,
            startDateTime,
            endDateTime,
            users,
            zone,
            ...rest
          }).state
        }
      })

      return newState
    })
}

const requests = { index }

export default requests
