import React from 'react'
import { PencilIcon } from '@heroicons/react/24/outline'
import ActionButton from './ActionButton'

const Edit = props => {
  return (
    <ActionButton title="Edit" {...props}>
      <PencilIcon className="-ml-1 md:m-0 h-6" />
    </ActionButton>
  )
}

export default Edit
