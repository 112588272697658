import Form from './Form'
import List from './List'
import New from './New'
import Show from './Show'
import Edit from './Edit'

const Meetings = () => {}

Meetings.Form = Form
Meetings.List = List
Meetings.New = New
Meetings.Show = Show
Meetings.Edit = Edit

export { Meetings as default, Form, List, New as NewForm, Show, Edit as EditForm }
