import React, { useContext, useState } from 'react'
import { Link, useMatch } from 'react-router-dom'
import { isNull } from 'lodash'

import Crash from './Crash'
import Footer from './Footer'
import Help from './Help'
import HelpButtons from './HelpButtons'
import WelcomeNav from './WelcomeNav'
import Grid from './grid'
import CookiePolicy from './CookiePolicy'

import CurrentUserContext from '../../contexts/CurrentUserContext'
import ErrorContext from '../../contexts/ErrorContext'

import twitterIcon from './images/twitter-social-icons-circle-blue.png'

const Container = ({ children }) => {
  const initialDisplayHelp = JSON.parse(localStorage.getItem('displayHelp') || 'false')
  const [displayHelp, setDisplayHelp] = useState(initialDisplayHelp)
  const [displayCookiePolicy, setDisplayCookiePolicy] = useState(false)

  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const { error } = useContext(ErrorContext)

  const signOutPageMatch = useMatch('/sign-out/*')
  const homePageMatch = useMatch('/')

  const handleHelpButtonClick = event => {
    event.preventDefault()

    localStorage.setItem('displayHelp', JSON.stringify(!displayHelp))
    setDisplayHelp(!displayHelp)
  }

  const handleCookiePolicyClick = event => {
    event.preventDefault()

    setDisplayCookiePolicy(!displayCookiePolicy)
  }

  return (
    <div className="flex flex-col min-h-screen" data-theme={currentUser.theme}>
      <main className="flex-grow">
        <div className="main-container max-w-md md:max-w-2xl">
          <Grid>
            <Link to="/" className="col-span-12 flex-center font-sans text-5xl uppercase">
              Zeitplan
            </Link>

            {isNull(signOutPageMatch) && !isNull(currentUser.token) && (
              <WelcomeNav currentUser={currentUser} setCurrentUser={setCurrentUser} />
            )}

            <hr className="col-span-12 h-4" />
          </Grid>

          {error.crashed() && (
            <Crash />
          )}

          {!error.crashed() && children}

          <hr className="col-span-12 my-4" />

          <Grid className="mt-3">
            <div className="col-span-4 w-full">
              <button className="link" onClick={handleCookiePolicyClick}>Cookie Policy</button>
            </div>

            <div className="col-span-4 justify-self-center">
              <a href="https://twitter.com/ZeitplanApp" target="_blank" rel="noreferrer">
                <img src={twitterIcon} alt="Twitter icon" width="25" />
              </a>
            </div>

            <div className="col-span-4 justify-self-end align-middle">
              {(isNull(homePageMatch) || currentUser.saved) && (
                <HelpButtons handleHelpButtonClick={handleHelpButtonClick} />
              )}
            </div>

            {displayCookiePolicy && <CookiePolicy />}
            {displayHelp && <Help />}
          </Grid>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default Container
