import React from 'react'
import ActionLink from '../../formFields/links/ActionLink'

const Empty = ({ meetingId }) => {
  return (
    <>
      <p className="col-span-12 flex-center h-12">
        No other users yet
      </p>

      <ActionLink to={`/meetings/${meetingId}`} className="button col-span-12 flex-center md:hidden">
        Back
      </ActionLink>
    </>
  )
}

export default Empty
