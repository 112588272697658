import DayOptions from './DayOptions'
import TimeOptions from './TimeOptions'
import Scroll from './Scroll'
import TimeSelect from './Select'

import { STANDARD_TIME_MAP } from '../../../helpers'

TimeSelect.DayOptions = DayOptions
TimeSelect.TimeOptions = TimeOptions
TimeSelect.Scroll = Scroll

const TimeMaps = {
  Standard: STANDARD_TIME_MAP,
}


export {TimeSelect as default, TimeMaps }
