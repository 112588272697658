import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from '../home'
import Meetings from '../meeting'
import Settings from './settings'
import Profile from './settings/Profile'
import Availabilities from '../availabilities'
import Container from './Container'
import UserAvailabilitiesListPage from '../availabilities/UserAvailabilitiesListPage'
import Faq from './Faq'
import SignOut from './settings/SignOut'
import MeetingNotFound from './MeetingNotFound'
import CurrentUserContext from '../../contexts/CurrentUserContext'
import SignIn from './SignIn'

const Site = () => {
  const { currentUser } = useContext(CurrentUserContext)

  return (
    <Container>
      <Routes>
        {!currentUser.saved && (
          <Route path="/" element={<Home />} />
        )}

        {currentUser.saved && (
          <Route path="/" element={<Meetings.List />} />
        )}

        <Route path="meetings" element={<Meetings.List />} />
        <Route path="meetings/new" element={<Meetings.New />} />
        <Route path="meetings/:id/edit" element={<Meetings.Edit />} />
        <Route path="meetings/:meetingId/settings" element={<Settings />} />
        <Route path="meetings/:meetingId" element={<Meetings.Show />} />
        <Route path="meetings/:meetingId/availabilities/:userToken/new" element={<Availabilities.New />} />
        <Route path="meetings/:meetingId/availabilities/:id/edit" element={<Availabilities.Edit />} />
        <Route path="meetings/:meetingId/availabilities/:userToken" element={<UserAvailabilitiesListPage />} />
        <Route path="faq" element={<Faq />} />
        <Route path="profile" element={<Profile />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-out" element={<SignOut />} />
        <Route path="meeting-not-found" element={<MeetingNotFound />} />
      </Routes>
    </Container>
  )
}

export default Site
