import React from 'react'

const CancelTokenContext = React.createContext()
const { Provider: CancelTokenProvider, Consumer: CancelTokenConsumer } = CancelTokenContext

export {
  CancelTokenContext as default,
  CancelTokenProvider,
  CancelTokenConsumer,
}
