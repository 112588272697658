import { useMemo } from 'react'

import { TIME_MAP, TIME_TYPES } from '../constants'
import convertTimeToString from "./convertTimeToString";

const STANDARD_TIME_MAP = () => {
  const memoizedTimeMap = useMemo(() => {
    return TIME_MAP.map(([hour, minute]) => {
      const value = convertTimeToString(hour, minute, TIME_TYPES.standard);

      return {
        hour,
        minute,
        value,
      }
    })
  }, [])

  return memoizedTimeMap
}

export { STANDARD_TIME_MAP }
