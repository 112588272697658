const createUserStorage = user => {
  const { id, token, timeZone, name, meetingTokens } = user

  localStorage.setItem('userId', id)
  localStorage.setItem('userToken', token)
  localStorage.setItem('timeZone', timeZone)
  localStorage.setItem('name', name)
  localStorage.setItem('meetingTokens', JSON.stringify(meetingTokens))

  return Promise.resolve(user)
}

const clearUserStorage = () => {
  localStorage.removeItem('userId')
  localStorage.removeItem('userToken')
  localStorage.removeItem('timeZone')
  localStorage.removeItem('name')
  localStorage.removeItem('meetingTokens')

  return Promise.resolve()
}

const clearLocationStorage = () => {
  localStorage.removeItem('locationIndex')
  localStorage.removeItem('locations')

  return Promise.resolve()
}

export {
  createUserStorage,
  clearUserStorage,
  clearLocationStorage
}
