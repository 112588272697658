import { PERIODS } from '../constants'

const convertToStandardTime = hour => {
  if (hour === 0) {
    return [12, PERIODS.am]
  }

  if (hour === 12) {
    return [hour, PERIODS.pm]
  }

  if (hour > 12) {
    return [hour - 12, PERIODS.pm]
  }

  return [hour, PERIODS.am]
}

export default convertToStandardTime
