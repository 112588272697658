import React from 'react'

const RemountContext = React.createContext()
const { Provider: RemountProvider, Consumer: RemountConsumer } = RemountContext

RemountContext.refresh = (count, setCount) => { setCount(count + 1) }

export {
  RemountContext as default,
  RemountProvider,
  RemountConsumer,
}
