import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '../grid'
import UserToken from './UserToken'
import Title from '../Title'
import HomeButton from '../HomeButton'
import ActionButton from '../../formFields/buttons/ActionButton'

import { useMountEffect } from '../../../helpers/effects'
import { clearUserStorage, clearLocationStorage, createUserStorage } from '../../../helpers/user'
import CurrentUserContext from '../../../contexts/CurrentUserContext'

const SignOut = () => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const [previousUser] = useState({ ...currentUser })
  const navigate = useNavigate()

  useMountEffect(() => {
    clearUserStorage()
    clearLocationStorage()

    setCurrentUser({ id: null, token: null, timeZone: null, name: '' })
  })

  const handleUndoClick = event => {
    event.preventDefault()

    createUserStorage(previousUser)
    setCurrentUser(previousUser)

    navigate(-1)
  }

  return (
    <Grid>
      <Title className="flex-center m-3">
        You have signed out.
      </Title>

      <div className="col-span-12 flex-center h-12">
         Here's your sign-in token for next time!
      </div>

      <UserToken currentUserToken={previousUser.token} />

      <hr className="col-span-12" />

      <div className="col-span-6">
        <ActionButton onClick={handleUndoClick} className="link">undo</ActionButton>
      </div>

      <div className="col-span-6 flex-center text-center justify-self-end">
        <HomeButton />
      </div>

      <hr className="col-span-12 mb-2" />
    </Grid>
  )
}

export default SignOut
