import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SELECT_TYPES } from '../../../helpers/constants'
import newTimeGreaterThanOldTime from '../../../helpers/time/newTimeGreaterThanOldTime'

const TimeOptions = props => {
  const {
    data,
    selectedTime,
    timeLimit,
    type,
    handleMouseOverTime,
    handleClick,
  } = props

  const handleOptionClick = (disabled, newTime) => event => {
    if (disabled) {
      return
    }

    handleClick(type)(newTime)
  }

  const handleMouseOver = time => event => {
    handleMouseOverTime(type)(time)
  }

  return data.map(props => {
    const {
      hour,
      minute,
      value,
    } = props

    const key = `${hour}_${minute}`
    let disabled = type === SELECT_TYPES.endTime && newTimeGreaterThanOldTime(timeLimit, { hour, minute })
    const selected = !disabled && selectedTime.hour === hour && selectedTime.minute === minute
    const classes = classNames("time-option", { "disabled": disabled })

    return (
      <div
        tabIndex={-1}
        key={key}
        className={classes}
        role="option"
        aria-selected={selected}
        onMouseOver={handleMouseOver({ hour, minute })}
        onClick={handleOptionClick(disabled, { hour, minute })}
      >
        <span tabIndex={-1}>{value}</span>
      </div>
    )
  })
}

TimeOptions.propTypes = {
  data: PropTypes.array,
  selectedTime: PropTypes.shape({
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  timeLimit: PropTypes.shape({
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired,
    value: PropTypes.string,
  }),
  type: PropTypes.oneOf(Object.keys(SELECT_TYPES)),
  handleMouseOverTime: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
}

TimeOptions.defaultProps = {
  timeLimit: {
    hour: 0,
    minute: 0,
  }
}

export default TimeOptions
