import axios from 'axios'

import { defaultHeaders, normalizeResponse } from '../helpers'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/meetings`

const index = async (meetingId, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.get(`${baseUrl}/${meetingId}/users`, { headers })

  const response = await request

  return normalizeResponse(response.data, 'user')
}

const services = { index }

export default services
