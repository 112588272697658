import React from 'react'
import { Link } from 'react-router-dom'

const WelcomeNav = ({ currentUser }) => {
  return (
    <ul className="contents">
      {!currentUser.saved && (
        <>
          <li className="col-span-4 flex-center">
            <Link to="/meetings" className="block link py-2">
              Meetings
            </Link>
          </li>

          <li className="col-span-4 flex-center">
            <Link to="/profile" className="w-full link flex-center">
              Create Profile
            </Link>
          </li>

          <li className="col-span-4 flex-center">
            <Link to="/sign-in" className="w-full link flex-center">
              Sign In
            </Link>
          </li>
        </>
      )}

      {currentUser.saved && (
        <>
          <li className="col-span-4 flex-center">
            <Link to="/meetings" className="block link py-2">
              Meetings
            </Link>
          </li>
          <li className="col-span-4 flex-center">
            <Link to="/profile" className="block link py-2">
              Edit Profile
            </Link>
          </li>
          <li className="col-span-4 flex-center">
            <Link to="/sign-out" className="block link py-2">
              Sign Out
            </Link>
          </li>
        </>
      )}
    </ul>
  )
}

export default WelcomeNav
