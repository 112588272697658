import React from 'react'

const Display = ({ allDayChecked, start, end, id, creatorToken }) => {
  const startTime = allDayChecked ? "12:00am" : start.value
  const endTime = allDayChecked ? "11:59pm" : end.value

  return (
    <>
      {start.weekdayValue} {startTime} - {endTime}
    </>
  )
}

export default Display
