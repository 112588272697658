import React from 'react'
import PropTypes from 'prop-types'
import { SELECT_TYPES } from '../../../helpers/constants'

const DayOptions = props => {
  const {
    data,
    selectedDay,
    handleMouseOverDay,
    handleClick,
    type,
  } = props

  const handleOptionClick = newDay => event => {
    handleClick(type)(newDay)
  }

  const handleMouseOver = day => event => {
    handleMouseOverDay(type)({ weekday: day })
  }

  return data.map(value => {
    const day = data.findIndex(day => day === value)

    let selected = selectedDay === day

    return (
      <div
        tabIndex={-1}
        key={day}
        className="time-option"
        role="option"
        aria-selected={selected}
        onMouseOver={handleMouseOver(day)}
        onClick={handleOptionClick(day)}
      >
        <span tabIndex={-1}>{value}</span>
      </div>
    )
  })
}

DayOptions.propTypes = {
  data: PropTypes.array,
  selectedDay: PropTypes.number.isRequired,
  handleMouseOverDay: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys(SELECT_TYPES)),
}

export default DayOptions
