import validateTimeChange from './validateTimeChange'

const getPrevTime = (hour, minute) => {
  validateTimeChange(hour, minute)

  if (minute === 0) {
    if (hour === 0) {
      return [23, 45]
    }

    return [hour - 1, 45]
  }

  return [hour, minute - 15]
}

export default getPrevTime
