import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { CheckIcon } from '@heroicons/react/24/solid'
import { PencilIcon, ClipboardDocumentIcon, BackwardIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/outline'

const icons = {
  check: CheckIcon,
  clipboardCopyOutline: ClipboardDocumentIcon,
  pencil: PencilIcon,
  rewindOutline: BackwardIcon,
  trashOutline: TrashIcon,
  xCircleOutline: XCircleIcon,
}

const IconListItem = ({ icon, className, children, ...rest }) => {
  let HeroIcon

  if (icon !== null) {
    HeroIcon = icons[icon]
  }

  const classes = classNames('my-4', className)

  return (
    <li className={classes} {...rest}>
      <span className="hidden md:inline">Click</span>
      <span className="md:hidden">Tap</span>
      {' '}
      {icon !== null && (
        <HeroIcon className="h-6" />
      )}
      {' '}
      {children}
    </li>
  )
}

IconListItem.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
}

IconListItem.defaultProps = {
  icon: null,
  className: '',
}

export default IconListItem
