import React from 'react'

const Input = props => {
  return (
    <input
      className="input-field"
      type="text"
      {...props}
    />
  )
}

export default Input
