import React from 'react'

const CurrentUserContext = React.createContext()
const { Provider: CurrentUserProvider, Consumer: CurrentUserConsumer } = CurrentUserContext

export {
  CurrentUserContext as default,
  CurrentUserProvider,
  CurrentUserConsumer,
}
