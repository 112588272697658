import handleAjaxError from './handleAjaxError'
import handleActiveError from './handleActiveError'
import notify from './notify'
import resetErrors from './resetErrors'

const Errors = () => {}

Errors.handleAjaxError = handleAjaxError
Errors.handleActiveError = handleActiveError
Errors.notify = notify
Errors.reset = resetErrors

export default Errors
