import axios from 'axios'
import jstz from 'jstimezonedetect'

import { defaultHeaders, normalizeResponse } from '../helpers'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/users`

const create = async cancelToken => {
  const timeZone = jstz.determine().name()
  const params = { user: { timeZone } }
  const headers = { 'Content-Type': 'application/json;charset=UTF-8', cancelToken }
  const request = axios.post(baseUrl, params, { headers })

  const response = await request

  return normalizeResponse(response.data, 'user')[0]
}

const show = async (id, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.get(`${baseUrl}/${id}`, { headers })

  const response = await request

  return normalizeResponse(response.data, 'user')[0]
}

const update = async (id, params, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.patch(`${baseUrl}/${id}`, params, { headers })

  await request

  return
}

const services = { create, show, update }

export default services
