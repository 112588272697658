import React from 'react'
import IconListItem from './IconListItem'

const EditMeeting = () => {
  return (
    <div className="help-container">
      <h3>Edit your Meeting</h3>

      <p>
        Here you can edit the name of your meeting or
        change the min and max time boundaries.
      </p>

      <hr />

      <ul>
        <IconListItem>
          <span className="small-button">Cancel</span> to discard changes
        </IconListItem>

        <IconListItem>
        <span className="small-button">Update</span> to save changes
        </IconListItem>
      </ul>
    </div>
  )
}

export default EditMeeting
