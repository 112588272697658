import React from 'react'
import classNames from 'classnames'

const Title = ({ className, children }) => {
  const classes = classNames("col-span-12 title relative rounded", className)

  return (
    <h2 className={classes}>
      {children}
    </h2>
  )
}

export default Title
