import React from 'react'
import { isEmpty, isNull } from 'lodash'
import { ArrowRightCircleIcon, UserCircleIcon } from '@heroicons/react/24/solid'

import { Row } from '../structure/grid'

const UserAvailabilityRow = ({ userName, userToken, userAvailabilities, handleClick }) => {
  return (
    <li key={userToken} className="grid grid-cols-12 hover:shadow-lg" onClick={handleClick(userToken)}>
      <div className="grid col-span-12 md:col-span-6 md:grid-rows-2 gap-2 grid-flow-col">
        <div className="grid md:row-span-2 flex-center">
          <Row className="flex-center">
            <UserCircleIcon className="block h-6" />
          </Row>

          <Row className="flex-center text-center">
            {(!isNull(userName) && !isEmpty(userName)) ? userName : userToken}
          </Row>
        </div>
      </div>

      <div className="grid col-span-12 md:col-span-6 md:grid-rows-2 gap-2 grid-flow-col">
        <div className="grid md:row-span-2 flex md:justify-end mr-4">
          <Row className="flex-center">
            {userAvailabilities.length === 0 && (
              <p>No Availabilities</p>
            )}

            {userAvailabilities.length === 1 && (
              <p>1 Availability</p>
            )}

            {userAvailabilities.length > 1 && (
              <p>{userAvailabilities.length} Availabilities</p>
            )}

            {userAvailabilities.length >= 1 && (
              <ArrowRightCircleIcon className="ml-2 hidden md:inline h-6" />
            )}
          </Row>
        </div>
      </div>
    </li>
  )
}

export default UserAvailabilityRow
