import { isNil } from 'lodash'
import { ERROR_TYPES } from '../helpers/constants'

class AppError {
  constructor(params = {}) {
    const { active, message, callback, type } = params

    this.active = isNil(active) ? false : active
    this.message = isNil(message) ? '' : message
    this.callback = isNil(callback) ? () => {} : callback
    this.type = isNil(type) ? ERROR_TYPES.app : type
  }

  get state() {
    return {
      active: this.active,
      message: this.message,
      callback: this.callback,
      type: this.type,
      crashed: this.crashed,
      showRetry: this.showRetry,
    }
  }

  showRetry = () => {
    return this.active && this.type === ERROR_TYPES.ajax
  }

  crashed = () => {
    return this.active && this.type === ERROR_TYPES.app
  }
}

export default AppError
