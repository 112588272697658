import React from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

const Select = ({ children, ...rest }) => {
  return (
    <div className="relative w-full">
      <select
        className="input-field"
        {...rest}
      >
        {children}
      </select>
      <div className="select-pointer">
        <ChevronDownIcon className="h-4" />
      </div>
    </div>
  )
}

export default Select
