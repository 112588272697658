import React, { useContext, useState } from 'react'
import Axios from 'axios'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import groupAvailabilityRequest from '../../requests/groupAvailabilities'
import meetingRequest from '../../requests/meetings'
import userRequest from '../../requests/users'

import Meetings from './index'
import { useMountEffect } from '../../helpers/effects'

import * as Constants from './timeRange/constants'
import MeetingTimeRange from './timeRange/Display'
import Errors from '../../helpers/errors'
import Permalink from '../permalink'
import Group from '../availabilities/Group'

import ErrorContext from '../../contexts/ErrorContext'
import LoadingContext from '../../contexts/LoadingContext'
import RemountContext from '../../contexts/RemountContext'
import CurrentUserContext from '../../contexts/CurrentUserContext'
import Title from '../structure/Title'
import UserAvailabilitiesList from '../availabilities/UserAvailabilitiesList'

const Show = () => {
  const { meetingId } = useParams()
  const navigate = useNavigate()

  const initialMeetingState = {
    times: { min: Constants.HOURS[0], max: Constants.HOURS[0] },
    types: { min: Constants.HOURS_LABEL, max: Constants.HOURS_LABEL },
  }

  const { currentUser } = useContext(CurrentUserContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const { error, setError } = useContext(ErrorContext)
  const { remountCount, setRemountCount } = useContext(RemountContext)

  const [meeting, setMeeting] = useState(initialMeetingState)
  const [groupAvailabilities, setGroupAvailabilities] = useState({})

  const location = useLocation()

  function MeetingNotFoundError(error) {
    this.error = error
    this.message = `Meeting ${meetingId} not found`
  }

  function LoadMeetingError(error) {
    this.error = error
    this.message = `Problem loading meeting ${meetingId}`
  }

  function LoadGroupAvailabilitiesError(error) {
    this.error = error
    this.message = `Problem loading group availabilities for meeting ${meetingId}`
  }

  function SetMeetingTokenError(error) {
    this.error = error
    this.message = `Problem setting meeting token for user ${currentUser.token}`
  }

  const loadMeeting = cancelToken => {
    const errorActive = Errors.handleActiveError((loading || error.active), () => RemountContext.refresh(remountCount, setRemountCount))

    if (errorActive) {
      return new Promise((resolve, _reject) => resolve())
    }

    return meetingRequest
      .load(meetingId, '', cancelToken)
      .then(setMeeting)
      .catch(error => {
        if (error.response && error.response.status === 404) {
          throw new MeetingNotFoundError(error)
        }

        throw new LoadMeetingError(error)
      })
  }
  const loadGroupAvailabilities = cancelToken => {
    const errorActive = Errors.handleActiveError((loading || error.active), () => RemountContext.refresh(remountCount, setRemountCount))

    if (errorActive) {
      return new Promise((resolve, _reject) => resolve())
    }

    groupAvailabilityRequest
      .index(meetingId, cancelToken, currentUser.timeZone)
      .then(setGroupAvailabilities)
      .catch(error => { throw new LoadGroupAvailabilitiesError(error) })
  }

  const setMeetingToken = async cancelToken => {
    const meetingToken = location.pathname.split('/').pop()

    if (currentUser.meetingTokens.includes(meetingToken)) {
      return new Promise((resolve, _reject) => resolve())
    }

    return userRequest
      .update({ user: { meetingToken }}, cancelToken)
      .catch(error => { throw new SetMeetingTokenError(error) })
  }

  useMountEffect(() => {
    let source = Axios.CancelToken.source()
    let { token: cancelToken } = source

    setLoading(true)

    loadMeeting(cancelToken)
      .then(() => setMeetingToken(cancelToken))
      .then(() => loadGroupAvailabilities(cancelToken))
      .then(() => setLoading(false))
      .catch(error => {
        setLoading(false)

        if (error instanceof MeetingNotFoundError) {
          return navigate('/meeting-not-found')
        }

        if (error instanceof LoadMeetingError) {
          return Errors.handleAjaxError(error, () => loadMeeting(cancelToken), setError)
        }

        if (error instanceof LoadGroupAvailabilitiesError) {
          return Errors.handleAjaxError(error, () => loadGroupAvailabilities(cancelToken), setError)
        }

        if (error instanceof SetMeetingTokenError) {
          return Errors.handleAjaxError(error, () => setMeetingToken(cancelToken), setError)
        }

        throw error
      })

    return () => source.cancel('Unmounting')
  }, remountCount)

  return (
    <Meetings.Form meetingId={meetingId}>
      <MeetingTimeRange
        meetingId={meetingId}
        minTime={meeting.times.min}
        maxTime={meeting.times.max}
        minType={meeting.types.min}
        maxType={meeting.types.max}
        name={meeting.name}
      />

      <Permalink id={meetingId} />

      <Title className="sub-title flex-center">
        Calculated Meeting Times
      </Title>

      <Group groupAvailabilities={groupAvailabilities} />

      <hr className="col-span-12" />

      <Title className="sub-title flex-center">
        My Availability
      </Title>

      <UserAvailabilitiesList meetingId={meetingId} userToken={currentUser.token} />
    </Meetings.Form>
  )
}

export default Show
