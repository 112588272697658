import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Title from '../../structure/Title'
import Grid from '../../structure/grid'
import FormFields from '../../formFields'
import { MINUTES_LABEL, HOUR_LABEL, HOURS_LABEL } from './constants'
import LoadingContext from '../../../contexts/LoadingContext'

const Display = ({ meetingId, minTime, maxTime, minType, maxType, name }) => {
  const { loading } = useContext(LoadingContext)

  let minTypeLabel = minType
  let maxTypeLabel = maxType

  if (minType === HOURS_LABEL) {
    if (minTime === 1) {
      minTypeLabel = HOUR_LABEL
    }
  }

  if (maxType === HOURS_LABEL) {
    if (maxTime === 1) {
      maxTypeLabel = HOUR_LABEL
    }
  }

  return (
    <>
      <Title className="grid grid-cols-12 gap-2 flex-center w-full">
        <div className="col-span-12 flex-center w-full py-2 px-3">
          {!loading && (
            <>
              <div className="flex-1 text-center">
                {name || "Untitled Meeting"}
              </div>
              <FormFields.Links.Edit to={`/meetings/${meetingId}/edit`} className="ml-auto" />
            </>
          )}
        </div>
      </Title>

      <Grid className="col-span-12 flex-center">
        <div className="col-span-12 flex-center w-full mb-3">
          {!loading && (
            <>
              {minTime} {minTypeLabel} - {maxTime} {maxTypeLabel}
            </>
          )}
        </div>
      </Grid>

      <div className="col-span-12">
        <hr />
      </div>
    </>
  )
}

Display.propTypes = {
  minTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minType: PropTypes.oneOf([MINUTES_LABEL, HOURS_LABEL]),
}

Display.defaultProps = {
  minTime: '',
  maxTime: '',
  minType: HOURS_LABEL
}

export default Display
