import normalize from 'json-api-normalizer'

const normalizeResponse = (data, type, with_key = false) => {
  const response = normalize(data)

  if (Object.keys(response).length === 0) {
    return []
  }

  return with_key ? response[type] : Object.values(response[type])
}

export default normalizeResponse
