const newTimeGreaterThanOldTime = (newTime, oldTime, allowEqual = true) => {
  if (allowEqual) {
    return newTime.hour > oldTime.hour ||
      (newTime.hour >= oldTime.hour && newTime.minute >= oldTime.minute)
  }

  return newTime.hour >= oldTime.hour ||
    (newTime.hour >= oldTime.hour && newTime.minute >= oldTime.minute)
}

export default newTimeGreaterThanOldTime
