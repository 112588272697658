class User {
  constructor(params = {}) {
    const {
      id,
      meetingTokens,
      name,
      saved,
      theme,
      timeZone,
      token,
      createdAt,
      updatedAt,
    } = params

    this.id = id
    this.meetingTokens = meetingTokens
    this.name = name
    this.saved = saved
    this.theme = theme
    this.timeZone = timeZone
    this.token = token
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}

export default User
