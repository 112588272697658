import { DateTime } from 'luxon'

import availabilityService from '../services/userAvailabilities'
import TimeState from '../models/TimeState'

const index = (meetingId, userToken, cancelToken) => {
  return availabilityService
    .index(meetingId, userToken, cancelToken)
    .then(availabilities => {
      const newState = {}

      availabilities.forEach(item => {
        const { id, attributes: { startTime, endTime, creator: { token: creatorToken }, ...rest } } = item
        const startDateTime = DateTime.fromISO(startTime)
        const endDateTime = DateTime.fromISO(endTime)

        return newState[id] = {
          ...new TimeState({
            id,
            saved: true,
            creatorToken,
            startDateTime,
            endDateTime,
            ...rest
          }).state
        }
      })

      return newState
    })
}

const requests = { index }

export default requests
