import React from 'react'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid'

import ActionLink from './ActionLink'

const Back = props => {
  const { className, ...rest } = props

  return (
    <ActionLink {...rest}>
      <ArrowLeftCircleIcon className={className} />
    </ActionLink>
  )
}

Back.defaultProps = {
  size: 18,
}

export default Back
