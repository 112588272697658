import React from 'react'

import CopyField from '../../formFields/CopyField'

const UserToken = ({ currentUserToken }) => {
  return (
    <>
      <div className="grid grid-cols-12 col-span-12 flex-center">
        <CopyField value={currentUserToken} />
      </div>

      <div className="col-span-12 flex-center text-sm">
        <p>
          This is your current user token. If you ever need to use Zeitplan on a different computer, phone, or tablet,
          sign in with this token.
        </p>
      </div>
    </>
  )
}

export default UserToken
