import validateTimeChange from './validateTimeChange'

const getNextTime = (hour, minute) => {
  validateTimeChange(hour, minute)

  if (minute < 45) {
    return [hour, minute + 15]
  }

  if (hour === 23) {
    return [0, 0]
  }

  return [hour + 1, 0]
}

export default getNextTime
