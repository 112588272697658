import React from 'react'

import Grid from './grid'
import HomeButton from './HomeButton'
import Title from './Title'

const MeetingNotFound = () => {
  return (
    <Grid>
      <Title className="flex-center m-3">
        Meeting Not Found
      </Title>

      <p className="col-span-12 w-full flex-center">
        Sorry, we couldn't find your meeting!
      </p>

      <div className="col-span-12 w-full flex-center">
        <HomeButton />
      </div>
    </Grid>
  )
}

export default MeetingNotFound
