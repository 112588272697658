import axios from 'axios'

import { defaultHeaders, normalizeResponse } from '../helpers'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/meetings`

const index = async (meetingId, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.get(`${baseUrl}/${meetingId}/availabilities`, { headers })

  const response = await request

  return normalizeResponse(response.data, 'availability')
}

const create = async (meetingId, params, cancelToken) => {
  const headers = { ...defaultHeaders(), ...cancelToken }
  const request = axios.post(`${baseUrl}/${meetingId}/availabilities`, params, { headers })

  const response = await request

  return normalizeResponse(response.data, 'availability')[0]
}

const show = async (meetingId, id, cancelToken) => {
  const headers = { ...defaultHeaders(), ...cancelToken }
  const request = axios.get(`${baseUrl}/${meetingId}/availabilities/${id}`, { headers })

  const response = await request

  return normalizeResponse(response.data, 'availability')[0]
}

const update = async (meetingId, id, params, cancelToken) => {
  const headers = { ...defaultHeaders(), ...cancelToken }
  const request = axios.put(`${baseUrl}/${meetingId}/availabilities/${id}`, params, { headers })

  const response = await request

  return normalizeResponse(response.data, 'availability')[0]
}

const services = { index, create, show, update }

export default services
