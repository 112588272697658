import React from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

const HelpButtons = ({ handleHelpButtonClick }) => {
  return (
    <p className="col-span-12 flex-center">
      <button title="Display Help">
        <QuestionMarkCircleIcon className="link h-6" onClick={handleHelpButtonClick} />
      </button>
      {/* <span className="mx-2 text-xs">
        |
      </span>
      <a href="/faq" className="text-xs link" title="Frequently Asked Questions">
        FAQ
      </a> */}
    </p>
  )
}

export default HelpButtons
