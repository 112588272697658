import { DateTime } from "luxon"

const findDifferenceBetweenWeekdays = (currWeekDay, newWeekDay) => {
  if (currWeekDay <= newWeekDay) {
    return newWeekDay - currWeekDay
  }

  return 7 - currWeekDay + newWeekDay
}

const findNewDateFromWeekday = newWeekday => {
  const currDate = DateTime.local()
  const currWeekDay = currDate.weekday === 7 ? 0 : currDate.weekday

  const difference = findDifferenceBetweenWeekdays(currWeekDay, newWeekday)

  return currDate.plus({ days: difference })
}

const findNewDayFromWeekday = newWeekday =>
  findNewDateFromWeekday(newWeekday).day

export {
  findNewDateFromWeekday as default,
  findNewDayFromWeekday
}
