import React, { useContext, useState } from 'react'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { groupBy } from 'lodash'

import availabilityRequest from '../../requests/availabilities'
import groupAvailabilityRequest from '../../requests/groupAvailabilities'
import meetingRequest from '../../requests/meetings'

import { Form } from '../meeting'
import { useMountEffect } from '../../helpers/effects'

import * as Constants from '../meeting/timeRange/constants'
import MeetingTimeRange from '../meeting/timeRange/Display'
import ErrorContext from '../../contexts/ErrorContext'
import Errors from '../../helpers/errors'
import Permalink from '../permalink'
import TimeState from '../../models/TimeState'
import Group from './Group'
import UserAvailabilities from './UserAvailabilities'
import NullUserAvailabilityRow from './NullUserAvailabilityRow'

import LoadingContext from '../../contexts/LoadingContext'
import RemountContext from '../../contexts/RemountContext'
import CurrentUserContext from '../../contexts/CurrentUserContext'

const List = ({ meetingId }) => {
  const initialMeetingState = {
    times: { min: Constants.HOURS[0], max: Constants.HOURS[0] },
    types: { min: Constants.HOURS_LABEL, max: Constants.HOURS_LABEL },
  }

  const { currentUser } = useContext(CurrentUserContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const { error, setError } = useContext(ErrorContext)
  const { remountCount, setRemountCount } = useContext(RemountContext)

  const [meeting, setMeeting] = useState(initialMeetingState)
  const [availabilities, setAvailabilities] = useState({})
  const [groupAvailabilities, setGroupAvailabilities] = useState({})
  const navigate = useNavigate()

  const loadMeeting = cancelToken => {
    const errorActive = Errors.handleActiveError((loading || error.active), () => RemountContext.refresh(remountCount, setRemountCount))

    if (errorActive) {
      return new Promise((resolve, _reject) => resolve())
    }

    return meetingRequest
      .load(meetingId, '', cancelToken)
      .then(setMeeting)
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setLoading(false)
          return navigate('/meeting-not-found')
        }

        setLoading(false)

        Errors.handleAjaxError(error, () => loadMeeting(cancelToken), setError)
      })
  }

  const loadAvailabilities = cancelToken => {
    const errorActive = Errors.handleActiveError((loading || error.active), () => RemountContext.refresh(remountCount, setRemountCount))

    if (errorActive) {
      return new Promise((resolve, _reject) => resolve())
    }

    availabilityRequest
      .index(meetingId, cancelToken)
      .then(setAvailabilities)
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadAvailabilities(cancelToken), setError)
      })
  }

  const loadGroupAvailabilities = cancelToken => {
    const errorActive = Errors.handleActiveError((loading || error.active), () => RemountContext.refresh(remountCount, setRemountCount))

    if (errorActive) {
      return new Promise((resolve, _reject) => resolve())
    }

    groupAvailabilityRequest
      .index(meetingId, cancelToken)
      .then(setGroupAvailabilities)
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadGroupAvailabilities(cancelToken), setError)
      })
  }

  useMountEffect(() => {
    let source = Axios.CancelToken.source()
    let { token: cancelToken } = source

    setLoading(true)

    loadMeeting(cancelToken)
      .then(() => loadAvailabilities(cancelToken))
      .then(() => loadGroupAvailabilities(cancelToken))
      .then(() => setLoading(false))

    return () => source.cancel('Unmounting')
  }, remountCount)

  const handleNewAvailabilityClick = userToken => event => {
    event.preventDefault()

    navigate(`/meetings/${meetingId}/availabilities/${userToken}/new`)
  }

  const groupedAvailabilities = groupBy(availabilities, a => a.creatorToken)

  Object.entries(groupedAvailabilities).forEach(([userToken, availabilities]) => {
    groupedAvailabilities[userToken] = availabilities.map(availability =>
      TimeState.degenerateState(availability.id, availability))
  })

  return (
    <Form meetingId={meetingId}>
      <MeetingTimeRange
        meetingId={meetingId}
        minTime={meeting.times.min}
        maxTime={meeting.times.max}
        minType={meeting.types.min}
        maxType={meeting.types.max}
        name={meeting.name}
      />

      <Permalink id={meetingId} />

      <UserAvailabilities
        meetingId={meetingId}
        availabilities={groupedAvailabilities}
        handleNewAvailabilityClick={handleNewAvailabilityClick}
      />

      {!Object.keys(groupedAvailabilities).some(userToken => userToken === currentUser.token) && (
        <ul className="col-span-12 availabilities-container">
          <NullUserAvailabilityRow userToken={currentUser.token} />
        </ul>
      )}

      <Group groupAvailabilities={groupAvailabilities} />
    </Form>
  )
}

export default List
