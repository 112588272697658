import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useCountdown } from '../../helpers/effects'
import ErrorContext from '../../contexts/ErrorContext'

const Error = ({ retryTime, handleErrorRetry }) => {
  const { error } = useContext(ErrorContext)
  const getCountdown = useCountdown(retryTime, error.callback)

  useEffect(() => {
    if (getCountdown() <= 0) {
      handleErrorRetry()
    }
  })

  const handleClickRetryNow = event => {
    event.preventDefault()

    error.callback()
    handleErrorRetry()
  }

  return (
    <>
      {getCountdown() > 0 && (
        <p className="text-xs">
          Something went wrong. Retrying in {getCountdown()}...
          {' '}
          <button className="link" onClick={handleClickRetryNow}>
            Retry Now
          </button>
        </p>
      )}
      {getCountdown() <= 0 && (
        <p className="text-xs ml-2">
          Retrying
        </p>
      )}

    </>
  )
}

Error.propTypes = {
  retryTime: PropTypes.number.isRequired,
  handleErrorRetry: PropTypes.func,
}

Error.defaultProps = {
  handleErrorRetry: () => {}
}

export default Error
