import React from 'react'
import { XCircleIcon } from '@heroicons/react/24/outline'

import ActionButton from './ActionButton'

const Cancel = props => {
  return (
    <ActionButton title="Cancel" {...props}>
      <XCircleIcon className="h-6" />
    </ActionButton>
  )
}

export default Cancel
