import React, { useContext } from 'react'

import TimeState from '../../models/TimeState'
import GroupRow from './GroupRow'
import LoadingContext from '../../contexts/LoadingContext'

const Group = ({ groupAvailabilities }) => {
  const { loading } = useContext(LoadingContext)

  const groupAvailabilitiesFromState =
    Object.entries(groupAvailabilities).map(([id, availability]) => TimeState.degenerateState(id, availability))

  return (
    <ul className="col-span-12 group-availabilities-container">
      {!loading && groupAvailabilitiesFromState.length === 0 && (
        <p className="w-full flex-center">
          There currently aren't any times that the meeting can occur.
          This may be because the other participants haven't entered any availabilities yet,
          or because there are no times where at least two people have overlapping
          availability.
        </p>
      )}


      {groupAvailabilitiesFromState.map(availability => {
        return (
          <GroupRow
            key={`group-availability-${availability.state.id}`}
            users={availability.state.users}
            allDayChecked={availability.state.allDayChecked}
            start={availability.state.start}
            end={availability.state.end}
          />
        )
      })}
    </ul>
  )
}

export default Group
