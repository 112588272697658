import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Availabilities from './index'
import Buttons from './buttons'
import TimeState from '../../models/TimeState'

const Availability = (props) => {
  const {
    availability,
    handleCancel,
    handleSelectFocus,
    handleCreate,
    handleUpdate,
  } = props

  const { id } = availability

  const [saving, setSaving] = useState(false)
  const [resetting, setResetting] = useState(false)

  const handleSelectOnFocus = event => {
    handleSelectFocus(id)
  }

  const handleSave = timeState => {
    timeState.id === 'new' ? handleCreate(timeState) : handleUpdate(timeState.id, timeState)

    setSaving(false)
  }

  const handleOnClickSave = () => {
    setSaving(true)
  }

  const handleCancelClick = () => {
    setResetting(true)

    handleCancel(id)
  }

  const handleDeleteClick = () => {
    const timeState = new TimeState({ ...availability, id, deleted: true })

    handleUpdate(id, timeState)
  }

  const handleFieldsReset = () => {
    setResetting(false)
  }

  return (
    <>
      <Availabilities.Fields
        availability={availability}
        reset={resetting}
        save={saving}
        handleSave={handleSave}
        handleReset={handleFieldsReset}
      />

      {availability.saved && (
        <Buttons.Show
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleSelectOnFocus}
          disabled={availability.creatorToken !== localStorage.getItem('userToken')}
        />
      )}

      {!availability.saved && (
        <Buttons.New
          persisted={availability.persisted}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleOnClickSave}
          disabled={availability.creatorToken !== localStorage.getItem('userToken')}
        />
      )}
    </>
  )
}

Availability.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleCancel: PropTypes.func,
  handleSelectFocus: PropTypes.func,
  handleCreate: PropTypes.func,
  handleUpdate: PropTypes.func,
}

Availability.defaultProps = {
  handleSelectFocus: () => {},
  handleCreate: () => {},
  handleUpdate: () => {},
  handleCancel: () => {},
}

export default Availability
