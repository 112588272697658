import React from 'react'

const ErrorContext = React.createContext()
const { Provider: ErrorProvider, Consumer: ErrorConsumer } = ErrorContext

export {
  ErrorContext as default,
  ErrorProvider,
  ErrorConsumer,
}
