import Buttons from './buttons'
import Links from './links'
import Input from './Input'
import Label from './Label'
import Select from './Select'

const FormFields = () => {}

FormFields.Buttons = Buttons
FormFields.Links = Links
FormFields.Input = Input
FormFields.Label = Label
FormFields.Select = Select

export default FormFields
