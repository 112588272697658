const handleActiveError = (active, callback) => {
  if (active) {
    setTimeout(() => callback(), 1000)

    return true
  }

  return false
}

export default handleActiveError
