import React from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'

import ActionButton from './ActionButton'

const Accept = props => {
  return (
    <ActionButton title="Accept" {...props}>
      <CheckIcon className="h-6" />
    </ActionButton>
  )
}

export default Accept
