import React from 'react'

const CookiePolicy = () => {
  return (
    <div className="col-span-12 w-full pt-1 help-container">
      <h3>Cookie Policy</h3>

      <p>
        Zeitplain requires a few strictly functional cookies. We do not use any kind of tracking or 3rd party cookies,
        and the information stored is strictly used to help make the site function better.

        <br />
        <br />

        The following items are kept in cookies:
      </p>

      <ul className="grid grid-cols-12 gap-2 list-disc">
        <li className="contents">
          <span className="col-span-4 proper-noun">displayHelp:</span>
          <span className="col-span-8">Used to determine if the help section is visible</span>
        </li>
        <li className="contents">
          <span className="col-span-4 proper-noun">name:</span>
          <span className="col-span-8">Your user's name</span>
        </li>
        <li className="contents">
          <span className="col-span-4 proper-noun">timeZone:</span>
          <span className="col-span-8">Your time zone</span>
        </li>
        <li className="contents">
          <span className="col-span-4 proper-noun">userId:</span>
          <span className="col-span-8">Your user ID</span>
        </li>
        <li className="contents">
          <span className="col-span-4 proper-noun">userToken:</span>
          <span className="col-span-8">Your user token</span>
        </li>
        <li className="contents">
          <span className="col-span-4 proper-noun">meetingTokens:</span>
          <span className="col-span-8">A list of visited meeting's tokens</span>
        </li>
      </ul>
    </div>
  )
}

export default CookiePolicy
