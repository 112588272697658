import React from 'react'
import PropTypes from 'prop-types'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'

import Scroll from './Scroll'
import Input from '../../formFields/Input'
import { pressedUpOrDown } from '../../../helpers'

const Select = props => {
  const {
    children,
    disabled,
    handleOnBlur,
    handleOnClick,
    handleInputKeyDown,
    handleChange,
    handleOnFocus,
    handleMouse,
    scrollPosition,
    isOpen,
    value,
    type,
    saved,
    className,
    ...rest
  } = props

  const handleBlur = event => {
    handleOnBlur(type)(event)
  }

  const handleClick = event => {
    handleOnClick(type)(event)
  }

  const preventDefaultKeyDown = key => {
    const enterKey = key === 'Enter'
    const tabKey = key === 'Tab'

    return pressedUpOrDown(key) || enterKey || (!tabKey && disabled)
  }

  const handleOnChange = event => {
    event.preventDefault()

    handleChange(type)(event.target.value)
  }

  const handleKeyDown = event => {
    const { key } = event

    if (preventDefaultKeyDown(key)) {
      event.preventDefault()
    }

    handleInputKeyDown(type)(key)
  }

  const childrenWithProps = React.Children.map(children, child => {
    const props = { type }

    if (React.isValidElement(child)) {
      return React.cloneElement(child, props)
    }

    return child
  })

  const selectClassName = classNames("time-select", className)

  return (
    <div className={selectClassName} onBlur={handleBlur} {...rest}>
      <div className="relative">
        <Input
          value={value}
          onChange={handleOnChange}
          onClick={handleClick}
          onFocus={handleOnFocus}
          onKeyDown={handleKeyDown}
          readOnly={disabled}
        />
        {!saved && (
          <div className="select-pointer">
            <ChevronDownIcon className="h-6" />
          </div>
        )}

        {isOpen && (
          <Scroll
            type={type}
            handleMouse={handleMouse}
            scrollPosition={scrollPosition}
          >
            {childrenWithProps}
          </Scroll>
        )}
      </div>
    </div>
  )
}

Select.propTypes = {
  disabled: PropTypes.bool,
  handleInputKeyDown: PropTypes.func,
  handleOnBlur: PropTypes.func,
  handleOnClick: PropTypes.func,
  handleOnFocus: PropTypes.func,
  handleChange: PropTypes.func,
  isOpen: PropTypes.bool,
  value: PropTypes.string,
  saved: PropTypes.bool,
}

Select.defaultProps = {
  disabled: false,
  handleInputKeyDown: type => event => {},
  handleOnBlur: type => event => {},
  handleOnClick: type => event => {},
  handleOnFocus: event => {},
  handleChange: () => {},
  isOpen: false,
  value: '',
  saved: false,
}

export default Select
