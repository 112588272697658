import React from 'react'
import { Link } from 'react-router-dom'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'

const Meeting = ({ meeting }) => {
  let { token, name } = meeting

  if (isNil(name) || name === '') {
    name = `Unnamed meeting @ ${DateTime.fromISO(meeting.createdAt).toLocaleString()}`
  }

  return (
    <li className="col-span-12 flex-center text-center hover:shadow-lg">
      <Link to={`/meetings/${token}`} className="w-full p-4 block">
        {name}
      </Link>
    </li>
  )
}

export default Meeting
