import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Buttons from './buttons'
import Display from './Display'

const Show = props => {
  const {
    availability,
    deleted,
    meetingId,
    handleDeleteClick,
    handleEditClick,
    handleUndoClick,
    undoCountdown,
  } = props

  const {
    id,
    creatorToken,
    allDay: allDayChecked,
    start,
    end,
  } = availability.state

  const classes = classNames(
    "col-span-8 flex-center md:hidden",
    { "line-through": deleted }
  )

  return (
    <>
      <div className={classes}>
        <Display
          id={id}
          allDayChecked={allDayChecked}
          start={start}
          end={end}
          creatorToken={creatorToken}
        />
      </div>

      <Buttons.Show
        id={id}
        meetingId={meetingId}
        deleted={deleted}
        disabled={creatorToken !== localStorage.getItem('userToken')}
        undoCountdown={undoCountdown}
        handleUndoClick={handleUndoClick}
        handleDeleteClick={handleDeleteClick}
        handleEditClick={handleEditClick}
      />
    </>
  )
}

Show.propTypes = {
  allowCancel: PropTypes.bool,
  deleted: PropTypes.bool,
  meetingId: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleUndoClick: PropTypes.func,
  undoCountdown: PropTypes.func,
}

Show.defaultPropTypes = {
  allowCancel: false,
  deleted: false,
  handleDeleteClick: () => {},
  handleEditClick: () => {},
  handleUndoClick: () => {},
  undoCountdown: () => {},
}

export default Show
