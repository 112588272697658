import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DateTime } from 'luxon'

import TimeSelect, { TimeMaps } from '../formFields/timeSelect'
import { DAY_FIELDS, DAY_NAMES, SELECT_TYPES, TIME_TYPES } from '../../helpers/constants'
import { useMountEffect } from '../../helpers/effects'
import { convertTimesToPositions, convertTimeToPosition, convertTimeToString, incrementTime, scrollPositionAmount } from '../../helpers'
import { handleKeyDownForSelect } from '../../helpers/keyHelper'
import newTimeLessThanOldTime from '../../helpers/time/newTimeLessThanOldTime'
import newTimeGreaterThanOldTime from '../../helpers/time/newTimeGreaterThanOldTime'
import { findNewDayFromWeekday } from '../../helpers/time/findNewDateFromWeekday'
import TimeState from '../../models/TimeState'
import Availabilities from './index'

const AvailabilityFields = ({ availability, save, reset, handleChange,  handleSave, handleReset }) => {
  const {
    id,
    allDay,
    saved,
    start,
    end,
  } = availability.state

  const initialStartDay = useMemo(() => {
    return {
      day: start.day,
      weekday: start.weekday,
      value: DAY_NAMES[start.weekday],
    }
  }, [start.day, start.weekday])

  const initialStartTime = useMemo(() => {
    return {
      hour: start.hour,
      minute: start.minute,
      value: start.value,
    }
  }, [start.hour, start.minute, start.value])

  const initialEndTime = useMemo(() => {
    return {
      hour: end.hour,
      minute: end.minute,
      value: end.value,
    }
  }, [end.hour, end.minute, end.value])

  const initialSelectOptions = {
    startDay: {
      visible: false,
      position: 0,
      hover: false,
    },
    startTime: {
      visible: false,
      position: 0,
      hover: false,
    },
    endTime: {
      visible: false,
      position: 0,
      hover: false,
    }
  }

  const initialScrollPositions = {
    day: 0,
    startTime: 0,
    endTime: 0,
  }

  const [allDayChecked, setAllDayChecked] = useState(allDay)
  const [startDay, setStartDay] = useState(initialStartDay)
  const [startTime, setStartTime] = useState(initialStartTime)
  const [endTime, setEndTime] = useState(initialEndTime)
  const [selectOptions, setSelectOptions] = useState(initialSelectOptions)
  const [scrollPositions, setScrollPositions] = useState(initialScrollPositions)

  const [timeStateToSave, setTimeStateToSave] = useState(availability)

  useMountEffect(() => {
    const startTimePosition = convertTimeToPosition(startTime.hour, startTime.minute)
    const endTimePosition = convertTimeToPosition(endTime.hour, endTime.minute)

    const newOptions = {
      ...selectOptions,
      startTime: {
        ...selectOptions.startTime,
        position: startTimePosition,
      },
      endTime: {
        ...selectOptions.endTime,
        position: endTimePosition,
      }
    }

    setSelectOptions(newOptions)
  }, id)

  useEffect(() => {
    let luxonWeekDay = startDay.weekday

    if (startDay.weekday === 0) {
      luxonWeekDay = 7
    }

    const startDateTime = DateTime.fromObject({ weekday: luxonWeekDay, hour: startTime.hour, minute: startTime.minute })
    const endDateTime = DateTime.fromObject({ weekday: luxonWeekDay, hour: endTime.hour, minute: endTime.minute })
    const newTimeState = new TimeState({ id, allDay: allDayChecked, saved, startDateTime, endDateTime })

    setTimeStateToSave(newTimeState)
    handleChange(newTimeState)
  }, [setTimeStateToSave, handleChange, allDayChecked, id, saved, startDay.weekday, startTime.hour, startTime.minute, endTime.hour, endTime.minute])

  useEffect(() => {
    if (save) {
      handleSave(timeStateToSave)
    }

  }, [save, handleSave, timeStateToSave])

  useEffect(() => {
    const restoreTimeStates = () => {
      setStartDay(initialStartDay)
      setStartTime(initialStartTime)
      setEndTime(initialEndTime)
    }

    if (reset) {
      restoreTimeStates()

      handleReset()
    }
  }, [reset, setStartDay, setStartTime, setEndTime, initialStartDay, initialStartTime, initialEndTime, handleReset])

  const setDayOrTime = (type, newState) => {
    switch (type) {
      case SELECT_TYPES.startDay:
        setStartDay({
          ...startDay,
          ...newState
        })
        break
      case SELECT_TYPES.startTime:
        setStartTime({
          ...startTime,
          ...newState
        })
        break
      case SELECT_TYPES.endTime:
        setEndTime({
          ...endTime,
          ...newState
        })
        break
      default:
    }
  }

  const setTypeSelectOptions = (type, newState) => {
    setSelectOptions({
      ...selectOptions,
      [type]: {
        ...selectOptions[type],
        ...newState,
      }
    })
  }

  const setTypeScrollPosition = (type, scrollPosition) => {
    setScrollPositions({
      ...scrollPositions,
      [type]: scrollPosition,
    })
  }

  const handleValueChange = type => value => {
    setDayOrTime(type, { value })
  }

  const handleMouseHoverSelect = type => value => event => {
    setTypeSelectOptions(type, { hover: value })
  }

  const handleMouseOverOption = type => nextState => {
    setDayOrTime(type, nextState)
  }

  const handleKeyDown = ({ type, key, value }) => {
    const pressedEnter = key === 'Enter'
    const pressedEscape = key === 'Escape'
    const pressedTab = key === 'Tab'

    if (pressedEscape) {
      setTypeSelectOptions(type, { visible: false })

      return
    }

    if (!pressedTab && (type !== SELECT_TYPES.startDay && (allDayChecked || saved))) {
      return
    }

    const {
      newScrollPosition,
      newSelectOptions,
      newTime
    } = handleKeyDownForSelect(type, key, value, selectOptions[type].visible)

    if (type === SELECT_TYPES.endTime && newTimeLessThanOldTime(newTime, startTime, false)) {
      return
    }

    if (type === SELECT_TYPES.startTime && (pressedEnter || pressedTab) && newTimeGreaterThanOldTime(newTime, endTime, false)) {
      let newEndTime = incrementTime(newTime)
      newEndTime.value = convertTimeToString(newEndTime.hour, newEndTime.minute, TIME_TYPES.standard)

      setDayOrTime(SELECT_TYPES.endTime, newEndTime)
    }

    setTypeScrollPosition(type, newScrollPosition)
    setTypeSelectOptions(type, newSelectOptions)
    setDayOrTime(type, newTime)
  }

  const handleKeyDownForType = type => key => {
    let value

    switch(type) {
      case SELECT_TYPES.startDay:
        value = startDay.weekday
        break
      case SELECT_TYPES.startTime:
        value = startTime
        break
      case SELECT_TYPES.endTime:
        value = endTime
        break
      default:
    }

    handleKeyDown({ type, key, value })
  }

  const handleClickSelectInput = type => event => {
    if (saved || (allDayChecked && type !== SELECT_TYPES.startDay)) {
      return
    }

    const visible = !selectOptions[type].visible
    const newTimes = convertTimesToPositions(startTime, endTime)
    const newScrollPosition = scrollPositionAmount(newTimes[type])

    setTypeSelectOptions(type, { visible })
    setTypeScrollPosition(type, newScrollPosition)
  }

  const handleClickSelectTime = (type, newTime) => {
    newTime.value = convertTimeToString(newTime.hour, newTime.minute, TIME_TYPES.standard)

    setDayOrTime(type, newTime)
    setTypeSelectOptions(type, { visible: false })

    if (type === SELECT_TYPES.startTime && newTimeGreaterThanOldTime(newTime, endTime, false)) {
      let newEndTime = incrementTime(newTime)
      newEndTime.value = convertTimeToString(newEndTime.hour, newEndTime.minute, TIME_TYPES.standard)

      setEndTime(newEndTime)
    }
  }

  const handleClickSelectDay = (type, weekday) => {
    const day = findNewDayFromWeekday(weekday)

    setDayOrTime(type, { day, weekday, value: DAY_NAMES[weekday] })
    setTypeSelectOptions(type, { visible: false })
  }

  const handleClickSelectOption = type => newDayOrTime => {
    if (DAY_FIELDS.includes(type)) {
     handleClickSelectDay(type, newDayOrTime)
    } else {
      handleClickSelectTime(type, newDayOrTime)
    }
  }

  // https://stackoverflow.com/a/38019906/398696
  const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
    if (relatedTarget === null) {
      return false
    }

    var node = relatedTarget.parentNode

    while (node !== null) {
      if (node === currentTarget) {
        return true
      }

      node = node.parentNode
    }

    return false;
  }

  const handleBlur = type => event => {
    if (focusInCurrentTarget(event)) {
      return
    }

    setTypeSelectOptions(type, { visible: false } )
  }

  const handleChangeAllDay = event => {
    if (saved) {
      return
    }

    setAllDayChecked(event.currentTarget.checked)
  }

  const startTimes = TimeMaps.Standard()
  const lastTime = {
    hour: 23,
    minute: 59,
    value: convertTimeToString(23, 59, TIME_TYPES.standard)
  }
  const endTimes = TimeMaps.Standard().concat(lastTime)

  // -----------------------------------------------------

  const dayClass = classNames(
    "availability-col md:flex-center",
    {
      "saved border-b": saved,
      "unsaved": !saved,
    }
  )

  const timeClass = classNames(
    dayClass,
    {
      "disabled": allDayChecked,
    }
  )

  return (
    <>
      <Availabilities.Mobile.Label htmlFor={`all_day_checkbox_${id}`} className={dayClass}>
        All Day
      </Availabilities.Mobile.Label>

      <div className={`col-span-6 flex items-center md:col-span-2 ${dayClass}`}>
        <input
          id={`all_day_checkbox_${id}`}
          type="checkbox"
          className="all-day"
          checked={allDayChecked}
          onChange={handleChangeAllDay}
          readOnly
        />
      </div>

      <Availabilities.Mobile.Label htmlFor={`availability_start_day_${id}`} className={dayClass}>
        Day
      </Availabilities.Mobile.Label>

      <div className={`col-span-6 flex items-center md:col-span-2 ${dayClass}`}>
        <TimeSelect
          id={`availability_start_day_${id}`}
          type={SELECT_TYPES.startDay}
          handleOnBlur={handleBlur}
          handleOnClick={handleClickSelectInput}
          handleInputKeyDown={handleKeyDownForType}
          handleChange={handleValueChange}
          handleMouse={handleMouseHoverSelect}
          scrollPosition={scrollPositions.day}
          isOpen={selectOptions.startDay.visible}
          value={`${startDay.value}`}
          disabled={true}
          saved={saved}
        >
          <TimeSelect.DayOptions
            data={DAY_NAMES}
            handleClick={handleClickSelectOption}
            selectedDay={startDay.weekday}
            handleMouseOverDay={handleMouseOverOption}
          />
        </TimeSelect>
      </div>

      <Availabilities.Mobile.Label htmlFor={`availability_start_time_${id}`} className={dayClass}>
        Start Time
      </Availabilities.Mobile.Label>

      <div className={`col-span-6 flex items-center md:col-span-3 ${timeClass}`}>
        <TimeSelect
          id={`availability_start_time_${id}`}
          type={SELECT_TYPES.startTime}
          handleOnBlur={handleBlur}
          handleOnClick={handleClickSelectInput}
          handleInputKeyDown={handleKeyDownForType}
          handleChange={handleValueChange}
          handleMouse={handleMouseHoverSelect}
          scrollPosition={scrollPositions.startTime}
          isOpen={selectOptions.startTime.visible}
          value={startTime.value}
          disabled={true}
          saved={saved}
        >
          <TimeSelect.TimeOptions
            data={startTimes}
            handleClick={handleClickSelectOption}
            selectedTime={startTime}
            handleMouseOverTime={handleMouseOverOption}
          />
        </TimeSelect>
      </div>

      <Availabilities.Mobile.Label htmlFor={`availability_end_time_${id}`} className={dayClass}>
        End Time
      </Availabilities.Mobile.Label>

      <div className={`col-span-6 flex items-center md:col-span-3 ${timeClass}`}>
        <TimeSelect
          id={`availability_end_time_${id}`}
          type={SELECT_TYPES.endTime}
          handleOnBlur={handleBlur}
          handleOnClick={handleClickSelectInput}
          handleInputKeyDown={handleKeyDownForType}
          handleChange={handleValueChange}
          handleMouse={handleMouseHoverSelect}
          scrollPosition={scrollPositions.endTime}
          isOpen={selectOptions.endTime.visible}
          value={endTime.value}
          disabled={true}
          saved={saved}
        >
          <TimeSelect.TimeOptions
            data={endTimes}
            handleClick={handleClickSelectOption}
            timeLimit={startTime}
            selectedTime={endTime}
            handleMouseOverTime={handleMouseOverOption}
          />
        </TimeSelect>
      </div>
    </>
  )
}

AvailabilityFields.propTypes = {
  reset: PropTypes.bool,
  handleChange: PropTypes.func,
  handleReset: PropTypes.func,
  handleSave: PropTypes.func,
}

AvailabilityFields.defaultProps = {
  reset: false,
  handleChange: () => {},
  handleReset: () => {},
  handleSave: () => {},
}

export default AvailabilityFields
