import React from 'react'

const LoadingContext = React.createContext()
const { Provider: LoadingProvider, Consumer: LoadingConsumer } = LoadingContext

export {
  LoadingContext as default,
  LoadingProvider,
  LoadingConsumer,
}
