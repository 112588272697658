import React, { useContext } from 'react'
import classNames from 'classnames'

import LoadingContext from '../../../contexts/LoadingContext'

const ActionButton = ({ className, disabled, handleOnClick, children, ...rest }) => {
  const { loading } = useContext(LoadingContext)

  const classes = classNames(
    "action-button",
    className,
    { "disabled": (loading || disabled) }
  )

  const handleClick = event => {
    event.preventDefault()

    if (!loading && !disabled) {
      handleOnClick()
    }
  }

  return (
    <button className={classes} onClick={handleClick} {...rest}>
      {children}
    </button>
  )
}

export default ActionButton
