import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FormLabel from '../../formFields/Label'

const Label = ({ span, className, children, ...rest }) => {
  const classes = classNames(
    `col-span-${span} flex items-center md:hidden`,
    className,
  )

  return (
    <FormLabel className={classes} {...rest}>
      {children}
    </FormLabel>
  )
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  span: PropTypes.string,
  className: PropTypes.string,
}

Label.defaultProps = {
  htmlFor: '',
  span: '6',
}

export default Label
