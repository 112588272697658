import axios from 'axios'

import { defaultHeaders, normalizeResponse } from '../helpers'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/meetings`

const create = async (params, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.post(baseUrl, params, { headers })

  const response = await request

  return normalizeResponse(response.data, 'meeting')[0]
}

const show = async (id, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.get(`${baseUrl}/${id}`, { headers })

  const response = await request

  return normalizeResponse(response.data, 'meeting')[0]
}

const update = async (id, params, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.put(`${baseUrl}/${id}`, params, { headers })

  const response = await request

  return normalizeResponse(response.data, 'meeting')[0]
}

const services = { create, show, update }

export default services
