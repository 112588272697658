import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Row = props => {
  const {
    element = 'div',
    col = 12,
    mdCol,
    className = '',
    center = false,
    children,
    ...rest
  } = props

  const classes = classNames(className, {
    [`col-span-${col}`]: col !== undefined,
    [`md:col-span-${mdCol}`]: mdCol !== undefined,
    'flex-center': center
  })

  const HTMLTag = element

  return (
    <HTMLTag className={classes} {...rest}>
      {children}
    </HTMLTag>
  )
}

Row.propTypes = {
  element: PropTypes.string,
  col: PropTypes.number,
  mdCol: PropTypes.number,
  className: PropTypes.string,
  center: PropTypes.bool,
}

export default Row
