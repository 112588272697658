import React from 'react'
import * as ReactDOMClient from 'react-dom/client'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/react'

import { BrowserRouter } from "react-router-dom"

const config = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_ENVIRONMENT_NAME,
  disabled: ['development', 'test'].includes(process.env.REACT_APP_ENVIRONMENT_NAME),
  projectRoot: process.env.REACT_APP_HONEYBADGER_PROJECT_ROOT,
  revision: process.env.RAILWAY_GIT_COMMIT_SHA,
}

const honeybadger = Honeybadger.configure(config)
const container = document.getElementById('root')
const root = ReactDOMClient.createRoot(container)

root.render(
  <React.StrictMode>
    <ErrorBoundary honeybadger={honeybadger}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
