import React from 'react'
import PropTypes from 'prop-types'

import FormFields from '../../../formFields'

const Show = props => {
  const {
    id,
    meetingId,
    deleted,
    disabled,
    undoCountdown,
    handleUndoClick,
    handleDeleteClick,
  } = props

  return (
    <>
      {deleted && (
        <div className="col-span-4 flex-center md:hidden">
          <FormFields.Buttons.Undo
            className="w-full small-button press-me flex-center"
            countdown={undoCountdown}
            handleOnClick={handleUndoClick}
          />
        </div>
      )}

      {!deleted && (
        <>
          <div className="col-span-2 flex-center md:hidden">
            <FormFields.Links.Edit
              className="w-full button small-button press-me"
              disabled={disabled}
              to={`/meetings/${meetingId}/availabilities/${id}/edit`}
            />
          </div>

          <div className="col-span-2 flex-center md:hidden">
            <FormFields.Buttons.Delete
              className="w-full button small-button press-me"
              disabled={disabled}
              handleOnClick={handleDeleteClick}
            />
          </div>
        </>
      )}
    </>
  )
}

Show.propTypes = {
  id: PropTypes.string.isRequired,
  meetingId: PropTypes.string.isRequired,
  deleted: PropTypes.bool,
  undoCountdown: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
}

Show.defaultProps = {
  deleted: false,
  handleDeleteClick: () => {},
  handleEditClick: () => {},
}

export default Show
