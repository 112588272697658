import React from 'react'
import FormFields from '../../formFields'

const RangeLabel = ({ children, ...rest }) => {
  return (
    <FormFields.Label {...rest}>
      {children}
    </FormFields.Label>
  )
}

export default RangeLabel
