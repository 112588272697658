import React from 'react'
import FormFields from '../formFields'

const BackButton = props => {
  return (
    <div className="absolute m-3">
      <FormFields.Links.Back {...props} />
    </div>
  )
}

export default BackButton
