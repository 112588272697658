import Accept from './Accept'
import Cancel from './Cancel'
import Delete from './Delete'
import Edit from './Edit'
import Undo from './Undo'
import Submit from './Submit'
import Settings from './Settings'

const Buttons = () => {}

Buttons.Accept = Accept
Buttons.Cancel = Cancel
Buttons.Delete = Delete
Buttons.Edit = Edit
Buttons.Undo = Undo
Buttons.Submit = Submit
Buttons.Settings = Settings

export default Buttons
