import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SELECT_TYPES } from '../../../helpers/constants'

const Scroll = props => {
  const {
    children,
    handleMouse,
    scrollPosition,
    type,
  } = props

  const scrollWindow = useRef(null)

  useEffect(() => {
    scrollWindow.current.scrollTop = scrollPosition

  }, [scrollPosition])

  const handleMouseEnter = event => {
    handleMouse(type)(true)
  }

  const handleMouseLeave = event => {
    handleMouse(type)(false)
  }

  return (
    <div
      tabIndex={-1}
      className="time-select-options"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={scrollWindow}
    >
      {children}
    </div>
  )
}

Scroll.propTypes = {
  handleMouse: PropTypes.func.isRequired,
  scrollPosition: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.keys(SELECT_TYPES)).isRequired,
}

export default Scroll
