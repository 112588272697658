import React, { useContext, useState } from 'react'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'

import Availabilities from './index'
import Availability from './Availability'
import Labels from './Labels'
import Empty from './Empty'
import DeletedAvailability from './DeletedAvailability'
import FormFields from '../formFields'
import { Row, SubGrid } from '../structure/grid'
import TimeState from '../../models/TimeState'

import availabilityRequest from '../../requests/availabilities'
import userAvailabilityRequest from '../../requests/userAvailabilities'

import Errors from '../../helpers/errors'
import { useMountEffect } from '../../helpers/effects'

import CurrentUserContext from '../../contexts/CurrentUserContext'
import ErrorContext from '../../contexts/ErrorContext'
import LoadingContext from '../../contexts/LoadingContext'
import RemountContext from '../../contexts/RemountContext'
import Grid from '../structure/grid/Grid'

const UserAvailabilitiesList = ({ meetingId, userToken }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const { loading, setLoading } = useContext(LoadingContext)
  const { error, setError } = useContext(ErrorContext)
  const { remountCount, setRemountCount } = useContext(RemountContext)
  const navigate = useNavigate()

  const [availabilities, setAvailabilities] = useState({})

  const loadAvailabilities = cancelToken => {
    const errorActive =
      Errors.handleActiveError(
        (loading || error.active),
        () => RemountContext.refresh(remountCount, setRemountCount)
      )

    if (errorActive) {
      return new Promise((resolve, _reject) => resolve())
    }

    return userAvailabilityRequest
      .index(meetingId, userToken, cancelToken)
      .then(setAvailabilities)
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadAvailabilities(cancelToken), setError)
      })
  }

  useMountEffect(() => {
    let source = Axios.CancelToken.source()
    let { token: cancelToken } = source

    setLoading(true)

    loadAvailabilities(cancelToken)
      .then(() => setLoading(false))

    return () => source.cancel('Unmounting')
  }, remountCount)

  const setSaveOn = id => {
    const newState = {
      ...availabilities,
      [id]: {
        ...availabilities[id],
        saved: true,
      }
    }

    setAvailabilities(newState)
  }

  const setSaveOff = id => {
    const newState = {
      ...availabilities,
      [id]: {
        ...availabilities[id],
        saved: false
      }
    }

    setAvailabilities(newState)
  }

  const handleUpdate = (id, timeState) => {
    Errors.reset(setError)
    setLoading(true)

    timeState.toTimeZone(currentUser.timeZone)

    return availabilityRequest
      .update(id, meetingId, timeState)
      .then(newState => {
        setLoading(false)

        setAvailabilities({
          ...availabilities,
          [newState.id]: { ...newState.state }
        })
      })
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => handleUpdate(id, timeState), setError)
      })
  }

  const handleDelete = id => {
    const { [id]: availability, ...rest } = availabilities

    setAvailabilities(rest)
  }

  const handleCancelEditClick = id => {
    setSaveOn(id)
  }

  const handleDeleteClick = id => () => {
    const timeState = new TimeState({ id, deleted: true })

    handleUpdate(id, timeState)
  }

  const handleNewAvailabilityClick = event => {
    event.preventDefault()

    navigate(`/meetings/${meetingId}/availabilities/${currentUser.token}/new`)
  }

  const availabilitiesFromState =
    Object.entries(availabilities).map(([id, availability]) => TimeState.degenerateState(id, availability))

  return (
    <>
      {!loading && Object.keys(availabilities).length > 0 && (
        <Labels />
      )}

      {!loading && Object.keys(availabilities).length === 0 && (
        <Empty />
      )}

      {availabilitiesFromState.map(availability => {
        const { id } = availability

        if (availability.deleted) {
          return (
            <DeletedAvailability
              key={`deleted-availability-${id}`}
              id={id}
              availability={availability}
              meetingId={meetingId}
              handleOnClick={handleUpdate}
              handleUndoExpire={handleDelete}
            />
          )
        } else {
          return (
            <SubGrid key={`availability-${id}`}>
              <Availabilities.Mobile.Show
                availability={availability}
                allowCancel={true}
                meetingId={meetingId}
                handleDeleteClick={handleDeleteClick(id)}
                handleEditClick={() => setSaveOff(id)}
              />

              <SubGrid size="md">
                <Availability
                  availability={availability}
                  allowCancel={true}
                  handleCancel={handleCancelEditClick}
                  handleUpdate={handleUpdate}
                  handleSelectFocus={setSaveOff}
                />
              </SubGrid>
            </SubGrid>
          )
        }
      })}

      {userToken === currentUser.token && (
        <Grid className="col-span-12">
          <Row col={6}>
            {/* <FormFields.Buttons.Submit className="w-full button press-me">
              Leave Meeting
            </FormFields.Buttons.Submit> */}
          </Row>

          <Row col={6}>
            <FormFields.Buttons.Submit
              className="w-full button press-me"
              onClick={handleNewAvailabilityClick}
            >
              New Availability
            </FormFields.Buttons.Submit>
          </Row>
        </Grid>
      )}
    </>
  )
}

export default UserAvailabilitiesList
