const roundToNextQuarter = (hour, minute) => {
  if (minute === 0) {
    return [hour, 0]
  }

  if (minute > 0 && minute <= 15) {
    return [hour, 15]
  }

  if (minute > 15 && minute <= 30) {
    return [hour, 30]
  }

  if (minute > 30 && minute <= 45) {
    return [hour, 45]
  }

  if (hour === 23) {
    return [0, 0]
  }

  return [hour + 1, 0]
}

export default roundToNextQuarter
