import React from 'react'
import PropTypes from 'prop-types'

import FormFields from '../../formFields'

const Name = ({ value, errors, handleOnChange, handleOnKeyDown }) => {
  const handleChange = event => {
    handleOnChange(event.target.value)
  }

  return (
    <>
      <div className="col-span-12 md:col-span-6 w-full flex-center">
        <FormFields.Label htmlFor="user_name">Name</FormFields.Label>
      </div>

      <div className="col-span-12 md:col-span-6 w-full flex-center">
        <FormFields.Input
          id="user_name"
          value={value}
          onChange={handleChange}
          onKeyDown={handleOnKeyDown}
        />
      </div>

      {errors.length > 0 && (
        <div className="col-span-12 flex-center h-8 error md:col-span-6 md:col-start-7">
          {errors.join(', ')}
        </div>
      )}
    </>
  )
}

Name.propTypes = {
  value: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Name
