import getNextTime from "./getNextTime"
import getPrevTime from "./getPrevTime"

const decrementTime = ({ hour, minute, ...rest }) => {
  if (hour === 0 && minute === 0) {
    return { hour, minute }
  }

  const [nextHour, nextMinute] = getPrevTime(hour, minute)

  return { hour: nextHour, minute: nextMinute, ...rest }
}

const incrementTime = ({ hour, minute, ...rest }) => {
  if (hour === 23 && minute === 45) {
    return { hour, minute }
  }

  const [nextHour, nextMinute] = getNextTime(hour, minute)

  return { hour: nextHour, minute: nextMinute, ...rest }
}

export { incrementTime, decrementTime }
