import React, { useContext, useState } from 'react'

import DisplayUser from './User'
import Empty from './Empty'

import User from '../../../models/User'

import { useMountEffect } from '../../../helpers/effects'
import meetingUserRequests from '../../../requests/meetingUsers'

import CancelTokenContext from '../../../contexts/CancelTokenContext'
import ErrorContext from '../../../contexts/ErrorContext'
import LoadingContext from '../../../contexts/LoadingContext'
import Errors from '../../../helpers/errors'
import CopyField from '../../formFields/CopyField'

const Signin = ({ meetingId, currentUserToken, handleClickUserToken }) => {
  const { cancelTokenSource, cancelToken } = useContext(CancelTokenContext)
  const { setError } = useContext(ErrorContext)
  const { setLoading } = useContext(LoadingContext)

  const [users, setUsers] = useState([])

  const loadMeetingUsers = () => {
    return meetingUserRequests
      .index(meetingId, cancelToken)
      .then(users => users.map(user => new User(user.attributes)))
      .then(setUsers)
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadMeetingUsers(), setError)
      })
  }

  useMountEffect(() => {
    setLoading(true)

    loadMeetingUsers()
      .then(() => setLoading(false))

    return () => cancelTokenSource.cancel('Unmounting')
  }, meetingId)

  return (
    <>
      <div className="grid grid-cols-12 col-span-12 flex-center">
        <CopyField value={currentUserToken} />
      </div>

      <div className="col-span-12 flex-center text-sm">
        <p>
          This is your current user token. If you ever need to use Zeitplan on a different computer, phone, or tablet,
          simply find this user token on this page and select it.
        </p>
      </div>

      <hr className="col-span-12" />

      {users.length === 0 && (
        <Empty meetingId={meetingId} />
      )}

      {users.length !== 0 && (
        <>
          <div className="col-span-12 flex-center h-12">
            Select to sign in as:
          </div>

          {users.map(user => {
            return (
              <DisplayUser
                key={user.id}
                currentUserToken={currentUserToken}
                token={user.token}
                handleClick={() => handleClickUserToken(user)}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export default Signin
