import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Form from './Form'
import TimeRange from './timeRange'

import meetingRequest from '../../requests/meetings'
import { useMountEffect } from '../../helpers/effects'
import LoadingContext from '../../contexts/LoadingContext'
import ErrorContext from '../../contexts/ErrorContext'
import Errors from '../../helpers/errors'
import * as Constants from './timeRange/constants'
import Name from './Name'
import Header from '../structure/Header'

const Edit = () => {
  const { id } = useParams()
  const mountSource = axios.CancelToken.source()
  const { token: mountCancelToken } = mountSource

  const location = useLocation()

  const initialState = {
    times: { min: Constants.HOURS[0], max: Constants.HOURS[0] },
    types: { min: Constants.HOURS_LABEL, max: Constants.HOURS_LABEL },
    name: '',
  }

  const [state, setState] = useState(initialState)

  const useQuery = () =>
    new URLSearchParams(location.search)

  let query = useQuery()

  const { setLoading } = useContext(LoadingContext)
  const { setError } = useContext(ErrorContext)
  const navigate = useNavigate()

  const loadMeeting = cancelToken => {
    return meetingRequest.load(id, query, mountCancelToken)
      .then(setState)
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadMeeting(cancelToken), setError)
      })
  }

  useMountEffect(() => {
    setLoading(true)

    loadMeeting(mountCancelToken)
      .then(() => setLoading(false))

    return () => mountSource.cancel('Unmounting')
  }, id)

  const handleCancelEditTimeRange = () => {
    navigate(`/meetings/${id}`)
  }

  const handleUpdateClick = newMeeting => {
    Errors.reset(setError)
    setLoading(true)

    newMeeting.name = state.name

    return meetingRequest
      .update(id, newMeeting, query)
      .then(() => meetingRequest.load(id, query))
      .then(setState)
      .then(() => setLoading(false))
      .then(() => navigate(`/meetings/${id}`))
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => handleUpdateClick(newMeeting), setError)
      })
  }

  const handleMetingNameChange = name => {
    setState({ ...state, name })
  }

  return (
    <Form meetingId={id}>
      <Header backPath={`/meetings/${id}`}>Edit Meeting</Header>

      <Name
        value={state.name}
        handleChange={handleMetingNameChange}
      />

      <TimeRange
        initialState={state}
        handleSubmit={handleUpdateClick}
        handleCancel={handleCancelEditTimeRange}
        isEdit
      />
    </Form>
  )
}

export default Edit
