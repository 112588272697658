import List from './List'
import Availability from './Availability'
import Empty from './Empty'
import Fields from './AvailabilityFields'
import New from './New'
import Edit from './Edit'
import Mobile from './mobile'

const Availabilities = () => {}

Availabilities.List = List
Availabilities.New = New
Availabilities.Edit = Edit
Availabilities.Item = Availability
Availabilities.Empty = Empty
Availabilities.Fields = Fields
Availabilities.Mobile = Mobile

export default Availabilities
