import React from 'react'

import { Row } from '../structure/grid'

const NullUserAvailabilityRow = ({ userToken }) => {
  return (
    <li key={userToken} className="grid grid-cols-12 hover:shadow-lg">
      <div className="grid col-span-12 md:col-span-6 md:grid-rows-2 gap-2 grid-flow-col">
        <div className="grid md:row-span-2 flex-center">
          <Row className="flex-center h-12" />
          <Row className="flex-center text-center" />
        </div>
      </div>

      <div className="grid col-span-12 md:col-span-6 md:grid-rows-2 gap-2 grid-flow-col">
        <div className="grid md:row-span-2 flex md:justify-end mr-4">
          <Row className="flex-center underline p-2" />
        </div>
      </div>
    </li>
  )
}

export default NullUserAvailabilityRow
