import { DateTime } from "luxon"

const degenerateTime = stateTime => {
  const weekday = stateTime.weekday === 0 ? 7 : stateTime.weekday
  const { month, day, hour, minute } = stateTime
  const newDateTime = DateTime.fromObject({ month, weekday, day, hour, minute })

  return newDateTime
}

const degenerateTimeState = (start, end) => {
  return {
    startDateTime: degenerateTime(start),
    endDateTime: degenerateTime(end),
  }
}

export default degenerateTimeState
