import { DAY_NAMES, TIME_TYPES } from "../constants"
import convertTimeToString from "./convertTimeToString"
import roundToNextQuarter from "./roundToNextQuarter"

const generateTime = dateTime => {
  const [hour, minute] = roundToNextQuarter(dateTime.hour, dateTime.minute)
  // Luxon has two issues with weekday:
  // - it defaults to Monday as start of week, with no option to change it
  // - weekdays are not zero based
  const weekday = dateTime.weekday === 7 ? 0 : dateTime.weekday
  const day = dateTime.day

  return {
    weekday,
    day,
    hour,
    minute,
    month: dateTime.month,
    value: convertTimeToString(hour, minute, TIME_TYPES.standard),
    weekdayValue: DAY_NAMES[weekday],
  }
}

const generateTimeState = ({ startDateTime, endDateTime, ...rest }) => {
  return {
    start: generateTime(startDateTime),
    end: generateTime(endDateTime),
    ...rest,
  }
}

export default generateTimeState
