import React from 'react'
import classNames from 'classnames'

import Grid from './Grid'

const SubGrid = ({ className, children, ...rest }) => {
  const classes = classNames("col-span-12", className)

  return (
    <Grid className={classes} {...rest}>
      {children}
    </Grid>
  )
}

export default SubGrid
