import React, { useContext, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Form from './Form'
import Name from './Name'
import TimeRange from './timeRange'
import meetingRequest from '../../requests/meetings'
import LoadingContext from '../../contexts/LoadingContext'
import ErrorContext from '../../contexts/ErrorContext'
import Errors from '../../helpers/errors/index'
import Header from '../structure/Header'

const New = () => {
  const [meetingName, setMeetingName] = useState('')
  const { setError } = useContext(ErrorContext)
  const { setLoading } = useContext(LoadingContext)

  const location = useLocation()
  const navigate = useNavigate()

  const useQuery = () =>
    new URLSearchParams(location.search)

  let query = useQuery()

  const handleCreateClick = newMeeting => {
    Errors.reset(setError)
    setLoading(true)

    newMeeting.name = meetingName

    meetingRequest
      .create(newMeeting, query)
      .then(newMeeting => {
        setLoading(false)

        navigate(`/meetings/${newMeeting.id}`)
      })
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => handleCreateClick(newMeeting), setError)
      })
  }

  return (
    <Form>
      <Header>
        New Meeting
      </Header>

      <Name initialValue={meetingName} handleChange={setMeetingName} />

      <TimeRange handleSubmit={handleCreateClick} />
    </Form>
  )
}

export default New
