import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Label from './Label'
import Buttons from '../../formFields/buttons'
import Select from '../../formFields/Select'

import * as Constants from './constants'
import { Row, SubGrid } from '../../structure/grid'

const TimeRange = ({ isEdit, initialState, handleSubmit, handleCancel }) => {
  const [state, setState] = useState(initialState)

  const typesMatch = state.types.min === state.types.max

  useEffect(() => {
    setState(initialState)
  }, [initialState])

  const handleTimeChange = type => event => {
    const value = event.target.value

    let newTimes = {
      ...state.times,
      [type]: Number(value),
    }

    const minTimeExceedsMaxTime = newTimes.min > newTimes.max

    if (typesMatch && minTimeExceedsMaxTime) {
      newTimes.max = newTimes.min
    }

    const newState = {
      ...state,
      times: newTimes
    }

    setState(newState)
  }

  const handleTypeChange = type => event => {
    const value = event.target.value

    let newTypes = {
      ...state.types,
      [type]: value,
    }

    let newTimes = {
      ...state.times,
      [type]: value === Constants.MINUTES_LABEL ? Constants.MINUTES[0] : Constants.HOURS[0],
    }

    const minTypeHours = newTypes.min === Constants.HOURS_LABEL
    const maxTypeMinutes = newTypes.max === Constants.MINUTES_LABEL
    const maxTypeSmallerThanMinType = minTypeHours && maxTypeMinutes

    if (maxTypeSmallerThanMinType) {
      newTypes.max = Constants.HOURS_LABEL

      newTimes = {
        min: Constants.HOURS[0],
        max: Constants.HOURS[0],
      }
    }

    const newState = {
      ...state,
      times: newTimes,
      types: newTypes,
    }

    setState(newState)
  }

  const handleSubmitClick = () => {
    const minTime = state.types.min === Constants.HOURS_LABEL ? state.times.min * 60 : state.times.min
    const maxTime = state.types.max === Constants.HOURS_LABEL ? state.times.max * 60 : state.times.max

    const newMeeting = {
      minTime,
      maxTime,
    }

    handleSubmit(newMeeting)
  }

  const renderMinTimeSelect = () => {
    if (state.types.min === Constants.MINUTES_LABEL) {
      return Constants.MINUTES.map(n => (
        <option key={n}>{n}</option>
      ))
    } else {
      return Constants.HOURS.map(n => (
        <option key={n}>{n}</option>
      ))
    }
  }

  const renderMaxTimeSelect = () => {
    if (state.types.max === Constants.MINUTES_LABEL) {
      return Constants.MINUTES.map(n => {
        const disabled = typesMatch && n < state.times.min

        return (
          <option key={n} disabled={disabled}>{n}</option>
        )
      })
    } else {
      return Constants.HOURS.map(n => {
        const disabled = typesMatch && n < state.times.min

        return (
          <option key={n} disabled={disabled}>{n}</option>
        )
      })
    }
  }

  return (
    <SubGrid>
      <div className="col-span-12">
        <SubGrid>
          <Row className="flex-center md:row-start-3 md:row-end-4 md:col-span-6">
            <Label htmlFor="min_time">
              Min Time
            </Label>
          </Row>

          <Row className="flex-center meeting-times h-12 md:row-start-4 md:row-end-5 md:col-span-6">
            <div className="mr-1 w-full">
              <Select
                id="min_time"
                value={state.times.min}
                onChange={handleTimeChange('min')}
              >
                {renderMinTimeSelect()}
              </Select>
            </div>

            <div className="ml-1 w-full">
              <Select
                id="min_time_type"
                value={state.types.min}
                onChange={handleTypeChange('min')}
              >
                <option value={Constants.MINUTES_LABEL}>
                  {Constants.MINUTES_LABEL}
                </option>
                <option value={Constants.HOURS_LABEL}>
                  {state.times.min === 1 ? Constants.HOUR_LABEL : Constants.HOURS_LABEL}
                </option>
              </Select>
            </div>
          </Row>

          <Row className="flex-center md:row-start-3 md:row-end-4 md:col-span-6">
            <Label htmlFor="max_time">
              Max Time
            </Label>
          </Row>

          <Row className="flex-center meeting-times h-12 md:row-start-4 md:row-end-5 md:col-span-6">
          <div className="mr-1 w-full">
            <Select
              id="max_time"
              value={state.times.max}
              onChange={handleTimeChange('max')}
            >
              {renderMaxTimeSelect()}
            </Select>
          </div>

          <div className="ml-1 w-full">
            <Select
              id="max_time_type"
              value={state.types.max}
              onChange={handleTypeChange('max')}
            >
              <option disabled={state.types.min === Constants.HOURS_LABEL} value={Constants.MINUTES_LABEL}>
                {Constants.MINUTES_LABEL}
              </option>
              <option value={Constants.HOURS_LABEL}>
                {state.times.max === 1 ? Constants.HOUR_LABEL : Constants.HOURS_LABEL}
              </option>
            </Select>
          </div>
        </Row>
        </SubGrid>
      </div>

      {isEdit && (
        <>
          <div className="col-span-6 flex-center justify-items-center">
            <Buttons.Submit type="button" className="w-full press-me h-12" handleOnClick={handleCancel}>
              Cancel
            </Buttons.Submit>
          </div>

          <div className="col-span-6 flex-center">
            <Buttons.Submit className="w-full press-me h-12" handleOnClick={handleSubmitClick}>
              Update
            </Buttons.Submit>
          </div>
        </>
      )}

      {!isEdit && (
        <>
          <div className="hidden md:flex md:col-span-6"></div>
          <div className="col-span-12 flex-center md:col-span-6">
            <Buttons.Submit className="w-full press-me h-12" handleOnClick={handleSubmitClick}>
              Create
            </Buttons.Submit>
          </div>
        </>
      )}
    </SubGrid>
  )
}

TimeRange.propTypes = {
  isEdit: PropTypes.bool,
  initialState: PropTypes.shape({
    times: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
    types: PropTypes.shape({
      min: PropTypes.oneOf([Constants.MINUTES_LABEL, Constants.HOURS_LABEL]).isRequired,
      max: PropTypes.oneOf([Constants.MINUTES_LABEL, Constants.HOURS_LABEL]).isRequired,
    }).isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
}

TimeRange.defaultProps = {
  isEdit: false,
  initialState: {
    times: { min: Constants.HOURS[0], max: Constants.HOURS[0] },
    types: { min: Constants.HOURS_LABEL, max: Constants.HOURS_LABEL },
  },
  handleCancel: () => {},
}

export default TimeRange
