import React, { useContext } from 'react'
import { useMatch } from 'react-router-dom'

import Doc from './docs'
import CurrentUserContext from '../../contexts/CurrentUserContext'

const Help = () => {
  const { currentUser } = useContext(CurrentUserContext)

  const root = useMatch('/')
  const meetings = useMatch('/meetings')
  const newMeeting = useMatch('/meetings/new')
  const profile = useMatch('/profile')
  const signIn = useMatch('/sign-in')
  const editMeeting = useMatch('/meetings/:id/edit')
  const availabilities = useMatch('/meetings/:meetingId')
  const newAvailability = useMatch('/meetings/:meetingId/availabilities/:userToken/new')
  const editAvailability = useMatch('/meetings/:meetingId/availabilities/:id/edit')

  return (
    <div className="col-span-12 w-full pt-1">
      {((root && currentUser.saved) || meetings) && <Doc.Meetings />}
      {newMeeting && <Doc.NewMeeting />}
      {editMeeting && <Doc.EditMeeting />}
      {signIn && <Doc.SignIn />}
      {profile && <Doc.Profile />}
      {!newMeeting && availabilities && <Doc.AvailabilitiesList />}
      {newAvailability && <Doc.NewAvailability />}
      {editAvailability && <Doc.EditAvailability />}
    </div>
  )
}

export default Help
