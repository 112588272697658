import userService from '../services/users'

const show = (userToken, cancelToken) => {
  return userService.show(userToken, cancelToken)
}

const create = cancelToken => {
  return userService.create(cancelToken)
}

const update = (params, cancelToken) => {
  const id = localStorage.getItem('userToken')

  params.userId = id

  return userService.update(id, params, cancelToken)
}

const requests = { create, show, update }

export default requests
