import React from 'react'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

const Refresh = () => {
  return (
    <ArrowPathIcon className="refresh spin breath h-5" />
  )
}

export default Refresh
