import React, { useContext, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

import Labels from './Labels'
import { Form } from '../meeting'
import Availability from './Availability'
import availabilityRequest from '../../requests/availabilities'
import { useMountEffect } from '../../helpers/effects'
import CurrentUserContext from '../../contexts/CurrentUserContext'
import LoadingContext from '../../contexts/LoadingContext'
import ErrorContext from '../../contexts/ErrorContext'
import Errors from '../../helpers/errors'
import Header from '../structure/Header'

const Edit = () => {
  const { meetingId, id } = useParams()

  let mountSource = axios.CancelToken.source()
  let { token: mountCancelToken } = mountSource

  const [availability, setAvailability] = useState({})
  const { currentUser } = useContext(CurrentUserContext)
  const { setLoading } = useContext(LoadingContext)
  const { setError } = useContext(ErrorContext)
  const navigate = useNavigate()

  const loadAvailability = cancelToken => {
    return availabilityRequest
      .showAvailibility(meetingId, id, cancelToken)
      .then(newState => {
        newState.saved = false
        newState.persisted = true

        setAvailability(newState)
      })
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadAvailability(cancelToken), setError)
      })
  }

  useMountEffect(() => {
    setLoading(true)

    loadAvailability(mountCancelToken)
      .then(() => setLoading(false))

    return () => mountSource.cancel('Unmounting')
  }, id)

  const handleUpdate = (id, timeState) => {
    Errors.reset(setError)
    setLoading(true)

    timeState.toTimeZone(currentUser.timeZone)

    return availabilityRequest
      .update(id, meetingId, timeState)
      .then(() => {
        setLoading(false)

        navigate(`/meetings/${meetingId}`)
      })
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => handleUpdate(id, timeState), setError)
      })
  }

  const handleCancel = () => {
    navigate(`/meetings/${meetingId}`)
  }

  return (
    <Form meetingId={meetingId}>
      <Header backPath={`/meetings/${meetingId}`}>Edit Availability</Header>

      <Labels />

      {Object.keys(availability).length > 0 && (
        <Availability
          availability={availability}
          allowCancel={true}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )}
    </Form>
  )
}

export default Edit
