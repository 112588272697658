import React from 'react'

import FormFields from '../formFields'
import { SubGrid } from '../structure/grid'

const Name = ({ value, handleChange }) => {
  const handleNameChange = event => {
    event.preventDefault()

    handleChange(event.target.value)
  }

  return (
    <div className="col-span-12">
      <SubGrid>
        <div className="col-span-4 h-12 flex-center">
          <FormFields.Label htmlFor="meeting_name">Meeting Name</FormFields.Label>
        </div>

        <div className="col-span-8 w-full flex-center">
          <FormFields.Input
            id="meeting_name"
            value={value}
            onChange={handleNameChange}
          />
        </div>
      </SubGrid>
    </div>
  )
}

export default Name
