import React from 'react'
import { HomeIcon } from '@heroicons/react/24/solid'

const HomeButton = () => {
  return (
    <a href="/" className="link">
      Home
      {' '}
      <HomeIcon className="inline h-6" />
    </a>
  )
}

export default HomeButton
