import axios from 'axios'

import { defaultHeaders, normalizeResponse } from '../helpers'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/users`

const index = async (userToken, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }

  const request = axios.get(`${baseUrl}/${userToken}/meetings`, { headers })

  const response = await request

  return normalizeResponse(response.data, 'meeting')
}

const services = { index }

export default services
