import React, { useContext, useState } from 'react'

import ErrorContext from '../../contexts/ErrorContext'
import ErrorField from '../structure/Error'
import Errors from '../../helpers/errors'
import LoadingContext from '../../contexts/LoadingContext'
import LoadingIcon from '../structure/LoadingIcon'
import Grid from '../structure/grid/Grid'

const Form = ({ meetingId, children }) => {
  const { error, setError } = useContext(ErrorContext)
  const { loading } = useContext(LoadingContext)
  const [retryTime, setRetryTime] = useState(5)

  const handleFormSubmit = event => {
    event.preventDefault()

    return null
  }

  const handleErrorRetry = () => {
    Errors.reset(setError)

    if (retryTime < 30) {
      setRetryTime(retryTime + 5)
    }
  }

  return (
    <form
      action="/"
      className="contents"
      autoComplete="off"
      onSubmit={handleFormSubmit}
    >
      <Grid className="-my-2">
        {children}

        <div className="col-span-12 flex justify-end">
          {error.showRetry() && (
            <ErrorField retryTime={retryTime} handleErrorRetry={handleErrorRetry} />
          )}

          {loading && (
            <LoadingIcon />
          )}
        </div>
      </Grid>
    </form>
  )
}

Form.defaultProps = {
  meetingId: null,
}

export default Form
