import React from 'react'
import { UserCircleIcon } from '@heroicons/react/24/solid'

import { Row } from '../structure/grid'

const GroupRow = ({ users, allDayChecked, start, end }) => {
  const startTime = allDayChecked ? "12:00am" : start.value
  const endTime = allDayChecked ? "11:59pm" : end.value

  return (
    <li className="grid grid-cols-12">
      <div className="grid col-span-12 md:col-span-6 md:grid-rows-2 gap-2 grid-flow-col">
        <div className="grid md:row-span-2 flex-center">
          <Row className="flex-center">
            <UserCircleIcon className="block h-6" />
          </Row>

          <Row className="flex-center text-center">
            {users.map(user => user.attributes.name || user.attributes.token).join(', ')}
          </Row>
        </div>
      </div>

      <div className="grid col-span-12 md:col-span-6 md:grid-rows-2 gap-2 grid-flow-col">
        <div className="grid md:row-span-2 flex md:justify-end mr-4">
          <Row className="flex-center">
            {start.weekdayValue} {startTime} - {endTime}
          </Row>
        </div>
      </div>
    </li>
  )
}

export default GroupRow
