import React from 'react'

const Settings = () => {
  return (
    <div className="help-container">
      <h3>User Profile</h3>

      <p>
        The Profile page is where you can set your name, timezone, or find your user token to easily switch devices.
      </p>

      <h4>Names and Tokens</h4>

      <p>
        By default, each user is created with a <span className="proper-noun">UserToken</span>.
        This is a unique, randomly generated string that Zeitplan uses to avoid requiring account creation.
        However, this token can be very confusing and hard to remember. Adding a name will make it easier for
        you and the other attendees to identify which availabilities are yours.
      </p>

      <p>
        <span className="underline">A note about the UserToken:</span>
        <br />
        The <span className="proper-noun">UserToken</span> is what you will use to login, and there is no password.
        Zeitplan aims to store the minimum amount of information possible - there are no email addresses stored, no passwords
        to remember. If someone were to get access to the database, they would find a bunch of datetimes, names, and timezones.
      </p>

      <h4>Timezones</h4>

      <p>
        Your timezone is detected automatically, and saved to your user. Most of the time, you
        won't ever need to worry about this. However, if you travel to a new timezone,
        you can select the new timezone here, and availabilities and meeting times will be
        presented in that timezone.
      </p>

      <h4>Signing In</h4>

      <p>
        You can sign in on a different device by clicking sign in at the top of the page.
      </p>
    </div>
  )
}

export default Settings
