import React from 'react'

const Labels = () => {
  return (
    <>
      <div className="avail-label-header md:col-span-2 md:flex-center">
        All Day
      </div>

      <div className="avail-label-header md:col-span-2 md:flex-center">
        Day
      </div>

      <div className="avail-label-header md:col-span-3 md:flex-center">
        Start Time
      </div>

      <div className="avail-label-header md:col-span-3 md:flex-center">
        End Time
      </div>

      <div className="avail-label-header md:col-span-2 md:flex-center">
      </div>
    </>
  )
}

export default Labels
