import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import Meeting from './Meeting'
import Grid from '../structure/grid'
import Header from '../structure/Header'

import userMeetingsRequest from '../../requests/userMeetings'
import { useMountEffect } from '../../helpers/effects'
import Errors from '../../helpers/errors'
import CurrentUserContext from '../../contexts/CurrentUserContext'
import CancelTokenContext from '../../contexts/CancelTokenContext'
import ErrorContext from '../../contexts/ErrorContext'
import LoadingContext from '../../contexts/LoadingContext'

const List = () => {
  const [meetings, setMeetings] = useState([])

  const { currentUser } = useContext(CurrentUserContext)
  const { cancelToken } = useContext(CancelTokenContext)
  const { setError } = useContext(ErrorContext)
  const { loading, setLoading } = useContext(LoadingContext)

  const loadMeetings = () => {
    return userMeetingsRequest.index(currentUser.token, cancelToken)
      .then(meetings => meetings.map(m => ({ token: m.id, ...m.attributes })))
      .then(setMeetings)
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadMeetings(), setError)
      })
  }

  useMountEffect(() => {
    setLoading(true)

    loadMeetings()
      .then(() => setLoading(false))
  })

  return (
    <Grid>
      <Header showBackButton={false}>Meetings</Header>

      <Grid element="ul" id="saved_availabilities" className="col-span-12 justify-center align-center">
        {!loading && meetings.length === 0 && (
          <>
            <li className="col-span-12 flex-center text-center h-12">
              No Meetings
            </li>
          </>
        )}

        {!loading && meetings.length !== 0 && (
          meetings.map(meeting => <Meeting key={meeting.token} meeting={meeting} />)
        )}
      </Grid>

      <div className="col-span-12 flex-center text-center h-12 md:justify-self-end">
        <Link to="/meetings/new" className="press-me button w-full">
          New Meeting
        </Link>
      </div>
    </Grid>
  )
}

export default List
