import { DAY_FIELDS, DAY_NAMES, TIME_TYPES } from "./constants"
import { convertTimeToPosition } from "./time/convertTimeToPosition"
import convertTimeToString from "./time/convertTimeToString"
import { findNewDayFromWeekday } from "./time/findNewDateFromWeekday"
import { decrementTime, incrementTime } from "./time/incrementAndDecrement"
import { scrollPositionAmount } from "./time/scrollPositionAmount"

const pressedUpOrDown = key => ['ArrowDown', 'ArrowUp'].includes(key)
const pressedEnter = key => key === 'Escape' || key === 'Enter' || key === 'Tab'

const getVisibility = (key, isVisible) => {
  if (pressedUpOrDown(key) && !isVisible) {
    return true
  }

  if (pressedEnter(key) && isVisible) {
    return false
  }

  return isVisible
}

const handleKeyDownForTime = (key, time, isVisible) => {
  let newTime = time
  let visible = getVisibility(key, isVisible)

  if (key === 'ArrowDown') {
    newTime = incrementTime(time)
  }

  if (key === 'ArrowUp') {
    newTime = decrementTime(time)
  }

  newTime.value = convertTimeToString(newTime.hour, newTime.minute, TIME_TYPES.standard)

  const position = convertTimeToPosition(newTime.hour, newTime.minute)
  const newScrollPosition = scrollPositionAmount(position)

  const newSelectOptions = {
    position,
    visible,
  }

  return {
    newScrollPosition,
    newSelectOptions,
    newTime,
  }
}

const handleKeyDownForDay = (key, weekday, isVisible) => {
  let newWeekday = weekday
  let visible = getVisibility(key, isVisible)

  if (key === 'ArrowDown' && weekday < 6) {
    newWeekday += 1
  }

  if (key === 'ArrowUp' && weekday > 0) {
    newWeekday -= 1
  }

  const newDay = findNewDayFromWeekday(newWeekday)

  const position = newWeekday
  const newScrollPosition = scrollPositionAmount(position)

  const newSelectOptions = {
    position,
    visible,
  }

  return {
    newScrollPosition,
    newSelectOptions,
    newTime: { day: newDay, weekday: newWeekday, value: DAY_NAMES[newWeekday] },
  }
}

const handleKeyDownForSelect = (type, key, value, isVisible) => {
  if (DAY_FIELDS.includes(type)) {
    return handleKeyDownForDay(key, value, isVisible)
  }

  return handleKeyDownForTime(key, value, isVisible)
}

export { handleKeyDownForSelect, pressedUpOrDown }
