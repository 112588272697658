import AppError from "../../models/AppError"
import ResponseError from "../../models/ResponseError"
import { ERROR_TYPES } from "../constants"
import notify from "./notify"

const handleAjaxError = (error, callback, setError) => {
  if (error.response) {
    const newError = new ResponseError({
      active: true,
      callback: () => callback(),
      message: error.response.data,
      status: error.response.status,
      type: ERROR_TYPES.ajax,
    })

    setError(newError.state)
  } else if (error.request) {
    const newError = new AppError({
      active: true,
      callback: () => callback(),
      message: error.request,
      type: ERROR_TYPES.ajax,
    })

    setError(newError.state)
  }

  notify(error)
}

export default handleAjaxError
