import axios from 'axios'

import { defaultHeaders, normalizeResponse } from '../helpers'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/meetings`

const index = async (meetingId, cancelToken) => {
  const headers = { ...defaultHeaders(), cancelToken }
  const request = axios.get(`${baseUrl}/${meetingId}/group_availabilities`, { headers })

  const response = await request

  const availabilities = normalizeResponse(response.data, 'availability')
  const users = normalizeResponse({ data: response.data.included }, 'user', true)

  availabilities.forEach(availability => {
    const currentUsers = availability.relationships.users.data

    currentUsers.forEach((user, index) => {
      const newUsers = normalizeResponse({ data: users[user.id] }, 'user')

      currentUsers[index] = newUsers
    })

    availability.relationships.users = availability.relationships.users.data.flat()
    delete availability.relationships.users.data
  })

  availabilities.sort((a, b) => {
    let aUserCount = a.relationships.users.length
    let bUserCount = b.relationships.users.length
    let aTimeDiff = new Date(a.attributes.endTime) - new Date(a.attributes.startTime)
    let bTimeDiff = new Date(b.attributes.endTime) - new Date(b.attributes.startTime)

    return bUserCount - aUserCount || bTimeDiff - aTimeDiff
  })

  return availabilities
}

const services = { index }

export default services
