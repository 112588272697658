const MINUTES_LABEL = 'Minutes'
const HOURS_LABEL = 'Hours'
const HOUR_LABEL = 'Hour'
const MINUTES = [15, 30, 45]
const HOURS = [...Array(25).keys()].slice(1)

export {
  HOURS,
  HOUR_LABEL,
  HOURS_LABEL,
  MINUTES,
  MINUTES_LABEL,
}
