import React from 'react'
import classNames from 'classnames'

const User = ({ currentUserToken, token, handleClick }) => {
  const classes = classNames(
    "col-span-12 flex-center border h-12 assign-user-button",
    { "disabled": token === currentUserToken }
  )

  const handleUserClick = event => {
    event.preventDefault()

    if (token === currentUserToken) {
      return
    }

    handleClick()
  }

  return (
    <button className={classes} onClick={handleUserClick}>
      {token}
    </button>
  )
}

export default User
