import React from 'react'
import { CogIcon } from '@heroicons/react/24/solid'

import ActionLink from '../links/ActionLink'

const Settings = ({ meetingId }) => {
  return (
    <ActionLink to={`/meetings/${meetingId}/settings`} className="link" title="Settings">
      <CogIcon className="inline" />
    </ActionLink>
  )
}

export default Settings
