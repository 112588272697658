import { DateTime } from 'luxon'

import availabilityService from '../services/availabilities'
import TimeState from '../models/TimeState'

const showAvailibility = (meetingId, id, cancelToken) => {
  return show(meetingId, id, cancelToken)
    .then(availability => {
      const { id, attributes: { startTime, endTime, creator: { name: creatorName, token: creatorToken }, ...rest } } = availability
      const startDateTime = DateTime.fromISO(startTime)
      const endDateTime = DateTime.fromISO(endTime)

      return new TimeState({
        id,
        saved: true,
        creatorName,
        creatorToken,
        startDateTime,
        endDateTime,
        ...rest
      })
    })
}

const index = (meetingId, cancelToken) => {
  return availabilityService
    .index(meetingId, cancelToken)
    .then(availabilities => {
      const newState = {}

      availabilities.forEach(item => {
        const { id, attributes: { startTime, endTime, creator: { name: creatorName, token: creatorToken }, ...rest } } = item
        const startDateTime = DateTime.fromISO(startTime)
        const endDateTime = DateTime.fromISO(endTime)

        return newState[id] = {
          ...new TimeState({
            id,
            saved: true,
            creatorName,
            creatorToken,
            startDateTime,
            endDateTime,
            ...rest
          }).state
        }
      })

      return newState
    })
}

const show = (meetingId, id, cancelToken) => {
  return availabilityService.show(meetingId, id, cancelToken)
}

const create = (meetingId, availability) => {
  availability = {
    ...availability.toWire,
    availabilitiesUsersAttributes: [{ userId: localStorage.getItem('userId') }],
  }

  const params = {
    availability,
  }

  return availabilityService.create(meetingId, params)
}

const update = (id, meetingId, timeState) => {
  const params = {
    availability: timeState.toWire,
  }

  return availabilityService
    .update(meetingId, id, params)
    .then(result => {
      const { startTime, endTime, creator: { name: creatorName, token: creatorToken }, ...rest } = result.attributes

      return new TimeState({
        id,
        saved: true,
        creatorName,
        creatorToken,
        startDateTime: DateTime.fromISO(startTime),
        endDateTime: DateTime.fromISO(endTime),
        ...rest,
      })
    })
}

const requests = {
  index,
  show,
  create,
  update,
  showAvailibility,
}

export default requests
