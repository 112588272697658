import React from 'react'
import { Tooltip } from 'react-tooltip'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'

const CopyField = ({ value }) => {
  const handleOnClick = event => {
    event.target.select()
  }

  // https://stackoverflow.com/a/30810322/398696
  const fallbackCopyTextToClipboard = text => {
    const textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'

      console.log('Fallback: Copying text command was ' + msg)
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  }

  const copyTextToClipboard = text => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text)

      return
    }

    navigator.clipboard.writeText(text)
  }

  const handleCopyInput = () => copyTextToClipboard(value)

  return (
    <>
      <div className="col-span-10 pr-2">
        <input
          className="w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
          id="name"
          type="text"
          value={value}
          onFocus={handleOnClick}
          onClick={handleOnClick}
          readOnly
        />
      </div>

      <div className="col-span-2">
        <button
          className="w-full p-2 border rounded-md bg-light-blue"
          data-tooltip-id="copied_message"
          data-data-tooltip-variant="success"
          data-tooltip-delay-hide={2000}
        >
          <ClipboardDocumentIcon className="inline h-6" />
        </button>

        <Tooltip id="copied_message" openOnClick afterShow={handleCopyInput}>
          <span>Copied!</span>
        </Tooltip>
      </div>
    </>
  )
}

export default CopyField
