import React from 'react'
import IconListItem from './IconListItem'

const NewAvailability = () => {
  return (
    <div className="help-container">
      <h3>Meetings</h3>

      <p>
        Any meetings you have visited will be listed here.
      </p>

      <hr />

      <ul>
        <IconListItem>
          the name of your meeting to load that meeting
        </IconListItem>

        <IconListItem>
          <span className="small-button">New Meeting</span> to create a new meeting
        </IconListItem>
      </ul>
    </div>
  )
}

export default NewAvailability
