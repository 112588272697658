import React, { useContext, useState } from 'react'
import Axios from 'axios'
import { isEmpty, isNull } from 'lodash'

import Header from '../structure/Header'
import { Form } from '../meeting/index'

import userRequest from '../../requests/users'

import Errors from '../../helpers/errors'
import { useMountEffect } from '../../helpers/effects'

import ErrorContext from '../../contexts/ErrorContext'
import LoadingContext from '../../contexts/LoadingContext'
import RemountContext from '../../contexts/RemountContext'
import UserAvailabilitiesList from './UserAvailabilitiesList'
import { useParams } from 'react-router'

const UserAvailabilitiesListPage = () => {
  const { meetingId, userToken } = useParams()

  const { loading, setLoading } = useContext(LoadingContext)
  const { error, setError } = useContext(ErrorContext)
  const { remountCount, setRemountCount } = useContext(RemountContext)

  const [userName, setUserName] = useState(userToken)

  const loadUser = cancelToken => {
    const errorActive =
      Errors.handleActiveError(
        (loading || error.active),
        () => RemountContext.refresh(remountCount, setRemountCount)
      )

    if (errorActive) {
      return new Promise((resolve, _reject) => resolve())
    }

    return userRequest
      .show(userToken, cancelToken)
      .then(newState => {
        setUserName(newState.attributes.name)
      })
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => loadUser(cancelToken), setError)
      })
  }

  useMountEffect(() => {
    let source = Axios.CancelToken.source()
    let { token: cancelToken } = source

    setLoading(true)

    loadUser(cancelToken)
      .then(() => setLoading(false))

    return () => source.cancel('Unmounting')
  }, remountCount)

  return (
    <Form meetingId={meetingId}>
      <Header backPath={`/meetings/${meetingId}`}>
        {!loading && (
          <>
            Availabilities for {(!isNull(userName) && !isEmpty(userName)) ? userName : userToken}
          </>
        )}
      </Header>

      <UserAvailabilitiesList meetingId={meetingId} userToken={userToken}/>
    </Form>
  )
}

export default UserAvailabilitiesListPage
