import React, { useRef, useState } from 'react'

import ActionLink from '../formFields/links/ActionLink'
import Grid, { Row } from '../structure/grid'

import createMeeting from '../structure/docs/images/create-meeting.png'
import copyMeetingPermalink from '../structure/docs/images/copy-meeting-permalink.png'
import addAvailability from '../structure/docs/images/add-availability.png'
import groupAvailabilities from '../structure/docs/images/group-availabilities.png'
import ImageGallery from 'react-image-gallery'
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline'

const Index = () => {
  const galleryRef = useRef(null)
  const [currentDescription, setCurrentDescription] = useState("Create a new meeting")
  const [playTimeoutId, setPlayTimeoutId] = useState(0)

  const images = [
    {original: createMeeting},
    {original: copyMeetingPermalink},
    {original: addAvailability},
    {original: groupAvailabilities}
  ]

  const descriptions = [
    "Create a new meeting",
    "Share the meeting link",
    "Everyone adds availability",
    "Find the best time to meet!",
  ]

  const handleSlide = index => {
    setCurrentDescription(descriptions[index])
  }

  const slideToImage = (dir) => {
    galleryRef.current.pause()
    const index = galleryRef.current.getCurrentIndex()
    galleryRef.current.slideToIndex(dir === 'back' ? index - 1 : index + 1)
  }

  const handlePlayTimeout = () => {
    clearTimeout(playTimeoutId)

    const newTimeoutId = setTimeout(() => {
      galleryRef.current.play()
    }, 3000)

    setPlayTimeoutId(newTimeoutId)
  }

  const handleSlideClick = (dir) => () => {
    slideToImage(dir)

    handlePlayTimeout()
  }

  return (
    <Grid className="w-10/12 m-auto">
      <Row element="h3" className="title flex-center text-center w-full">
          Zeitplan is a calendar app, inverted.
      </Row>
      <Row className="card w-full flex-center">
        Instead of scheduling your appointments,
        you schedule your free time. Then, send the meeting's link to your friends, colleagues -
        anyone you want to meet with regularly. Once everyone has entered their availabilities,
        Zeitplan calculates the best time to meet.
      </Row>
      <Row center>
        <ActionLink to="/meetings/new" className="button press-me h-12 w-full flex-center md:w-auto">
          Get Started
        </ActionLink>
      </Row>

      <Row element="hr" />

      <Grid className="col-span-12 sub-title title flex-center text-center w-full h-12">
        <Row center col={4}>
          <button onClick={handleSlideClick('back')} className="w-full flex-center">
            <ArrowLeftCircleIcon className="h-6" />
          </button>
        </Row>
        <Row center col={4}>
          {currentDescription}
        </Row>
        <Row center col={4}>
          <button onClick={handleSlideClick('next')} className="w-full flex-center">
            <ArrowRightCircleIcon className="h-6" />
          </button>
        </Row>
      </Grid>

      <Row element="ul" className="text-center list-none">
        <li className="card">
          <ImageGallery
            autoPlay
            disableKeyDown
            items={images}
            onBeforeSlide={handleSlide}
            showNav={false}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            slideInterval={4000}
            ref={galleryRef}
          />
        </li>
      </Row>
    </Grid>
  )
}

export default Index
