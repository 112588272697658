import React from 'react'
import { isUndefined } from 'lodash'

import Container from './Container'
import { Form } from '../meeting'
import Title from './Title'
import Buttons from '../formFields/buttons'


const Loading = ({ meetingId }) => {
  return (
    <Container>
      <Form>
        <Title className="h-12" />

        <div className="h-24" />

        {!isUndefined(meetingId) && (
          <Buttons.Settings />
        )}
      </Form>
    </Container>
  )
}

export default Loading
