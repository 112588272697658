import React from 'react'
import classNames from 'classnames'

import ActionButton from './ActionButton'

const Submit = ({ children, className, ...rest }) => {
  const classes = classNames(className, 'button')

  return (
    <ActionButton className={classes} {...rest}>
      {children}
    </ActionButton>
  )
}

export default Submit
