import React from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'

import ActionButton from './ActionButton'

const Delete = props => {
  return (
    <ActionButton title="Delete" {...props}>
      <TrashIcon className="h-6" />
    </ActionButton>
  )
}

export default Delete
