import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Header from '../Header'
import Grid from '../grid'
import TimeZoneSelect from './TimeZoneSelect'
import Name from './Name'
import UserToken from './UserToken'
import FormFields from '../../formFields'
import Errors from '../../../helpers/errors'

import usersRequests from '../../../requests/users'
import CurrentUserContext from '../../../contexts/CurrentUserContext'
import CancelTokenContext from '../../../contexts/CancelTokenContext'
import ErrorContext from '../../../contexts/ErrorContext'
import LoadingContext from '../../../contexts/LoadingContext'
import ActionButton from '../../formFields/buttons/ActionButton'

const Profile = () => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const { cancelToken } = useContext(CancelTokenContext)
  const { setError } = useContext(ErrorContext)
  const { setLoading } = useContext(LoadingContext)
  const navigate = useNavigate()

  const defaultName = {
    value: !currentUser.saved ? '' : (currentUser.name || ''),
    errors: [],
  }

  const [currentTimeZone, setCurrentTimeZone] = useState(currentUser.timeZone || 'UTC')
  const [name, setName] = useState(defaultName)

  const goBack = () => navigate(-1)

  const handleSave = () => {
    setLoading(true)

    const params = {
      user: {
        name: name.value,
        timeZone: currentTimeZone,
      }
    }

    return usersRequests
      .update(params, cancelToken)
      .then(() => {
        setCurrentUser({ ...currentUser, name: name.value, timeZone: currentTimeZone, saved: true })
      })
      .then(() => setLoading(false))
      .then(goBack)
      .catch(error => {
        setLoading(false)

        Errors.handleAjaxError(error, () => handleSave(), setError)
      })
  }

  const handleNameChange = value => {
    setName({ ...name, value })
  }

  const handleNameKeyDown = event => {
    if (event.key === 'Enter') {
      return handleSave()
    }
  }

  const handleSaveClick = event => {
    event.preventDefault()

    if (name.value === '') {
      const newErrors = ["can't be blank"]

      setName({ ...name, errors: newErrors })

      return
    }

    setName({ ...name, errors: [] })

    handleSave()
  }

  return (
    <Grid>
      <Header>Profile</Header>

      <UserToken currentUserToken={currentUser.token} />

      <hr className="col-span-12" />

      <Name
        value={name.value}
        errors={name.errors}
        handleOnChange={handleNameChange}
        handleOnKeyDown={handleNameKeyDown}
      />

      <TimeZoneSelect value={currentTimeZone} handleTimeZoneChange={setCurrentTimeZone} />

      <div className="col-span-6">
        <ActionButton className="button press-me flex-center w-full h-12" onClick={goBack}>
          Cancel
        </ActionButton>
      </div>

      <div className="col-span-6">
        <FormFields.Buttons.Submit className="w-full h-12 press-me" onClick={handleSaveClick}>
          {!currentUser.saved && 'Save'}
          {currentUser.saved && 'Update'}
        </FormFields.Buttons.Submit>
      </div>
    </Grid>
  )
}

export default Profile
